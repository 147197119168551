import * as Tripetto from 'tripetto-collector';
import { BlockComponentFactory } from '../../helpers/blocks/factory';
import { Number } from 'tripetto-block-number/collector';
import { Component } from '@angular/core';

@Component({
  templateUrl: './number.html'
})
export class NumberBlockComponent extends BlockComponentFactory<NumberBlock> {}

@Tripetto.block({
  type: 'node',
  identifier: 'tripetto-block-number',
  ref: NumberBlockComponent
})
export class NumberBlock extends Number {

  private valueChanged = false;

  onFocus(el: HTMLInputElement) {
    this.focus();
    el.value = this.value;
  }

  onBlur(el: HTMLInputElement) {
    this.blur();
    if (this.valueChanged || el.value) {
      el.value = parseFloat(this.value).toLocaleString();
    } else {
      el.value = this.value = null;
    }
  }

  onScroll($event) {
    $event.preventDefault();
  }

  onValueChanged(el: HTMLInputElement, newValue) {
    const roundingCoeff = Math.round(1 / parseFloat(el.step));

    setTimeout(() => this.value = this.parseNumericValue(newValue, roundingCoeff), 0);
  }

  parseNumericValue(value, roundingCoeff): string {
    // if decimals allowed i.e. roundingCoeff > 1 return unchanged if ending in decimal separator
    // so that they can add decimals after the separator
    if (roundingCoeff > 1 && value.slice(-1) === '.') {
      return value;
    }
    const num = parseFloat(value);
    if (isNaN(num)) {
      return '';
    } else {
      return (Math.round(num * roundingCoeff) / roundingCoeff).toString();
    }
  }

  getFloatValue(value): string {
    const parsedValue = value.replace(/[^0-9.]/g, '');
    this.valueChanged = <boolean>parsedValue;
    return parsedValue;
  }

}
