<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<mat-form-field class="w-100 mt-3 mb-3">
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search Name, Email, Business name...">
</mat-form-field>

<div class="results-wrapper">

  <table class="w-100" mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="first_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let element"> {{element['user']['first_name']}}</td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let element"> {{element['user']['last_name']}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>E-Mail</th>
      <td mat-cell *matCellDef="let element">
        <button mat-flat-button (click)="editUser(element['user'])">{{element['user']['email']}}</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="business">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Business Name</th>
      <td mat-cell *matCellDef="let element"> {{element['user'].business?.name}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <i class="fas fa-circle"
           [ngClass]="{'text-warning':element.status.code === 0,
                       'text-success':element.status.code === 2,
                       'text-primary':element.status.code === 1}"></i>
        {{element.status.label}}
      </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Progress</th>
      <td mat-cell *matCellDef="let element">
        <div class="pr-4" *ngIf="getSurveyProgress(element); let surveyProgress">
          <div class="progress" *ngIf="isProgressBarEligible(element) else progressText">
            <div class="progress-bar" role="progressbar" [ngStyle]="{width: surveyProgress+'%'}"><span class="small d-block">{{surveyProgress}}%</span></div>
          </div>
          <ng-template #progressText>{{surveyProgress}}%</ng-template>
        </div>
        <div *ngIf="!getSurveyProgress(element)">N/A</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Updated At</th>
      <td mat-cell *matCellDef="let element"> {{element['response_updated_at'] | formatDate | date:'short'}}</td>
    </ng-container>

    <ng-container matColumnDef="more">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="viewResponses(element)" *ngIf="isEDMember">
            <mat-icon>visibility</mat-icon>
            <span>View survey responses</span>
          </button>
          <button mat-menu-item (click)="takeSurvey(element)" *ngIf="isEDMember">
            <mat-icon>edit</mat-icon>
            <span>Edit survey responses</span>
          </button>
          <button mat-menu-item (click)="removeUserFromSurvey(element)">
            <mat-icon>delete_outline</mat-icon>
            <span>Remove from survey</span>
          </button>
          <button mat-menu-item (click)="editUser(element['user'])">
            <mat-icon>person</mat-icon>
            <span>User profile</span>
          </button>
          <button mat-menu-item (click)="resendInvitation(element)">
            <mat-icon>email</mat-icon>
            <span>Re-send invitation</span>
          </button>
          <button mat-menu-item (click)="sendReminder(element)">
            <mat-icon>email</mat-icon>
            <span>Send reminder</span>
          </button>
          <button mat-menu-item (click)="excludeFromResults(element)" *ngIf="!element['exclude_from_results']">
            <mat-icon>block</mat-icon>
            <span>Exclude from results</span>
          </button>
          <button mat-menu-item (click)="includeInResults(element)" *ngIf="element['exclude_from_results']">
            <mat-icon>add</mat-icon>
            <span>Include in results</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>

<mat-paginator [pageSizeOptions]="[10,30,50,100,500]" showFirstLastButtons></mat-paginator>
