import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Survey, SurveyDefinition} from '../../../../../model/common';
import {DialogService} from '../../../../../component/dialog';
import {InviteSurveyUsersModalComponent} from '../../../surveys/modals/invite-survey-users-modal/invite-survey-users-modal.component';
import {Subscription} from 'rxjs';
import {SurveyResultsComponent} from '../../../survey/survey-results/survey-results.component';
import {NavigationExtras, Router} from '@angular/router';
import {EditSurveyDetailsComponent} from '../../../surveys/modals/edit-survey-details/edit-survey-details.component';
import {SurveyEditorComponent} from '../../../../../components/survey-editor/survey-editor.component';
import {NotificationService} from '../../../../../component/notification';
import {MessageComponent} from '../../../../../component/message';
import {SurveyService} from '../../../../../core/survey.service';
import {AuthApiService} from '../../../../../core/auth-api.service';
import {UtilityService} from '../../../../../components/utility.service';
import {CompileSurveyComponent} from '../../../survey/compile-survey/compile-survey.component';
import {EdService} from '../../../../../core/ed.service';

@Component({
  selector: 'app-dashboard-survey',
  templateUrl: './dashboard-survey.component.html',
  styleUrls: ['./dashboard-survey.component.scss']
})
export class DashboardSurveyComponent implements OnInit, OnDestroy {
  @Input() survey: Survey;
  @Output() changed = new EventEmitter();
  @ViewChild('message') message: MessageComponent;

  edPath = this.auth.getEDPath();
  subsCan = new Subscription();

  isEDMember: boolean = false;
  isEDSurveyManager: boolean = false;
  isEDOwner: boolean = false;

  submittedCount = 0;
  inProgressCount = 0;
  surveyTakersCount = 0;

  constructor(
    public surveyService: SurveyService,
    private dialog: DialogService,
    private auth: AuthApiService,
    private router: Router,
    private notification: NotificationService,
    private util: UtilityService,
    private edService: EdService,
  ) {
  }

  ngOnInit() {
    this.isEDMember = this.auth.isEDMember();
    this.isEDSurveyManager = this.auth.isEDSurveyManager();
    this.isEDOwner = this.auth.isEDOwner();
  }

  onSurveyResponses($event) {
    this.survey.responses = $event;
    this.initProgressCounters();
  }

  initProgressCounters() {
    this.submittedCount = 0;
    this.inProgressCount = 0;
    this.surveyTakersCount = this.survey.responses ? this.survey.responses.length : 0;
    if (this.survey.responses) {
      this.survey.responses.forEach(response => {
        if (response.submitted) {
          this.submittedCount++;
        }
        if (response.progress && !response.submitted) {
          this.inProgressCount++;
        }
      });
    }
  }

  inviteUsers() {
    const dialogRef = this.dialog.open(InviteSurveyUsersModalComponent, {
      disableClose: true,
      width: '850px',
      maxHeight: '90vh'
    });

    dialogRef.componentInstance.survey = this.survey;

    this.subsCan.add(dialogRef.componentInstance.onSaved.subscribe(() => {
      this.changed.emit();
    }));
  }

  ngOnDestroy(): void {
    this.subsCan.unsubscribe();
  }

  viewResults() {
    const dialogRef = this.dialog.open(SurveyResultsComponent, {
      disableClose: false,
      panelClass: 'dialog-lg',
    });

    dialogRef.componentInstance.isModal = true;
    this.subsCan.add(dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.init(this.survey.id);
    }));

    this.subsCan.add(dialogRef.componentInstance.onClose.subscribe(() => {
      dialogRef.close();
    }));
  }

  canEdit() {
    return this.survey.status == 'planning' || this.survey.status == 'paused';
  }

  duplicate() {
    let url = this.auth.getEDPath() + `/surveys/start`;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        'duplicateSurveyID': this.survey.id,
      }
    };

    this.router.navigate([url], navigationExtras);
  }

  pause() {
    this.dialog.confirm({
      title: 'Pause survey',
      message: 'Are you sure?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, Pause'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.pause(this.survey.id).subscribe(r => {
        this.message.renderSuccess();
        this.changed.emit();
      }, e => this.message.renderApiError(e));
    }));
  }

  unpause() {
    this.dialog.confirm({
      title: 'Start survey',
      message: 'Are you sure?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, Start'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.unpause(this.survey.id).subscribe(r => {
        this.message.renderSuccess();
        this.changed.emit();
      }, e => this.message.renderApiError(e));
    }));
  }

  cancel() {
    this.dialog.confirm({
      title: 'Cancel survey',
      message: 'Are you sure?',
      cancelButton: 'No',
      acceptButton: 'Yes, Cancel the survey'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.cancel(this.survey.id).subscribe(r => {
        this.message.renderSuccess();
        this.changed.emit();
      }, e => this.message.renderApiError(e));
    }));
  }


  edit() {
    const dialogRef = this.dialog.open(EditSurveyDetailsComponent, {
      disableClose: true,
      panelClass: 'dialog-md'
    });

    dialogRef.componentInstance.survey = this.survey;
    dialogRef.componentInstance.onSaved.subscribe(() => {
      this.changed.emit();
    });
  }

  editDefinition() {
    this.edService.getDefinition(this.survey.survey_definition_id).subscribe(r => {
        const dialogRef = this.dialog.open(SurveyEditorComponent, {
          maxWidth: '70vw',
          maxHeight: '90vh',
          minHeight: '600px',
          minWidth: '750px',
          disableClose: true
        });

        dialogRef.componentInstance.surveyDefinition = r;
        dialogRef.componentInstance.survey = this.survey;

        this.subsCan.add(dialogRef.componentInstance.saveClicked.subscribe((surveyDefinition: SurveyDefinition) => {
          this.surveyService.updateDefinition(this.survey.id, surveyDefinition).subscribe(r => {
            this.notification.success('Success.', 'Saved', {duration: 1000});
            this.changed.emit();
            dialogRef.close();
          }, e => {
            this.notification.error('Save failed.', e.error['message'], {duration: 0});
          });
        }));

        this.subsCan.add(dialogRef.componentInstance.closeClicked.subscribe(() => {
          dialogRef.close();
        }));
      },
      e => {
        this.message.renderApiError(e);
      }
    );
  }

  viewSurvey() {
    this.util.previewSurvey(this.survey.id);
  }


  compileSurvey() {
    const dialogRef = this.dialog.open(CompileSurveyComponent, {
      disableClose: true,
      panelClass: 'dialog-lg'
    });

    dialogRef.componentInstance.survey = this.survey;
  }

}
