<div class="dashboard-header">
  <div class="row">
    <div class="col-md-8">
      <h1 class="dashboard-header__title">Thank you for choosing Sensible Surveys!</h1>
      <p class="dashboard-header__desc">Let's get started.</p>
    </div>
    <div class="col-md-4 d-flex flex-column justify-content-center">
      <a mat-raised-button color="primary" class="mb-2 w-100"
        *ngIf="isEDOwner"
        [routerLink]="[ EDPath + '/surveys/start']">
        <mat-icon>add</mat-icon>
        Start a new survey
      </a>
    </div>
  </div>
</div>

<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<div class="mt-4">
  <mat-card-content>
    <app-dashboard-survey
      class="mb-4"
      *ngFor="let survey of surveys"
      [survey]="survey"
      (changed)="onSurveyChanged()"
    ></app-dashboard-survey>
  </mat-card-content>
</div>




