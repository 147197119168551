import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MessageComponent} from "../../component/message";
import {MatSelect} from "@angular/material";
import {ED} from "../../model/common";
import {UserService} from "../../core/user.service";
import {DialogService} from "../../component/dialog";
import {NotificationService} from "../../component/notification";
import {AuthApiService} from "../../core/auth-api.service";
import {EdService} from "../../core/ed.service";
import {UtilityService} from "../utility.service";

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {


  @ViewChild('message') messageBox: MessageComponent;
  @ViewChild('filterRolesSelect') filterRolesSelect: MatSelect;

  @Input() title = false;

  @Input() hideRoleColumn = false;
  @Input() hideEDColumn = false;
  @Input() hideBusinessColumn = false;
  @Input() hideEDFilter = false;
  @Input() hideRoleFilter = false;

  @Input() userRoles = [];
  private availableUserRoles = [];
  public currentUserRole = null;

  @Input() filterED: ED | null = <ED>{};
  @Input() filterEmail: string | null;


  users = [];
  page = {
    size: 10,
    totalElements: 0,
    pageNumber: 0
  };

  EDs = [];
  filterRoles = [];
  restrictRoles = [];

  constructor(
    private userService: UserService,
    private dialog: DialogService,
    private notification: NotificationService,
    private authApi: AuthApiService,
    private edService: EdService,
    private util: UtilityService
  ) {
  }

  ngOnInit() {

    if (this.userRoles.length) {
      this.restrictRoles = this.userRoles.map(r => r.value);
    }

    this.userRoles = this.userRoles.length ? this.userRoles : this.authApi.userRoles;
    this.currentUserRole = this.authApi.user.role_id;
    this.availableUserRoles = this.userRoles.filter(userRole => userRole.value >= this.currentUserRole);

    this.hideEDFilter = !!this.authApi.user.ed;

    this.fetchData();
    this.messageBox.renderApiError();
    this.edService.all().subscribe(r => {
      this.EDs = r['data'];
    }, e => this.messageBox.renderApiError(e));

  }

  fetchData() {
    let roleIDs = this.filterRoles.length ? this.filterRoles : this.restrictRoles;
    let ed_id = this.filterED.id;

    this.messageBox.renderApiError();
    this.userService.all(this.page.pageNumber + 1, this.page.size, {
      ed_id: ed_id,
      email: this.filterEmail,
      role_id: roleIDs.join(',')
    }).subscribe(result => {
      this.users = result['data'];
      this.page.totalElements = result['total'];
      this.page.size = result['per_page'];
    }, e => this.messageBox.renderApiError(e));
  }

  setPage($event) {
    this.page.pageNumber = $event['offset'];
    this.fetchData();
  }

  editUser(user) {
    this.util.editUser(user, this.availableUserRoles).subscribe(() => {
      this.fetchData();
    });
  }

  delUser(user) {
    this.dialog.confirm({
      title: 'Delete user',
      message: `Are you sure you want to delete user <br>
                <strong class="text-danger"> ${user.first_name} ${user.last_name}</strong>?
                `,
      cancelButton: 'Cancel',
      acceptButton: 'Delete'
    }).afterClosed().subscribe((r => {
      if (!r) return;

      this.messageBox.renderApiError();
      this.userService.deleteUser(user.id).subscribe(r => {
        this.messageBox.renderSuccess(r);
        this.fetchData();
      }, e => this.messageBox.renderApiError(e));
    }));
  }

  // autocomplete hacks...
  onAutoComplete() {
    setTimeout(() => {
      this.filterED = <ED>this.filterED['name'];
    }, 0);
  }

  resetFilterEd() {
    this.filterED = <ED>{};
  }

  getRowClass(row) {
    const userRole: string = row.role.role;
    return 'row-' + userRole.toLowerCase().replace(/\s/g, '-');
  }
}
