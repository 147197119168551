<div class="dashboard-header">
    <div class="d-flex">
        <div class="flex-grow-1">
            <h1 class="dashboard-header__title"><span *ngIf="title">{{title}}</span></h1>
            <p class="dashboard-header__desc">Create update and modify system, survey users.</p>
        </div>
        <button mat-fab color="primary" class="" title="Add New User"
                (click)="editUser(null)">
            <mat-icon>person_add</mat-icon>
        </button>
    </div>
</div>

<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>
<mat-card class="material mat-typography">
  <mat-card-content>
    <form class="table-filters" (keydown.enter)="fetchData()">
      <div class="row">
        <div class="col-md-3" *ngIf="!hideEDFilter">
          <mat-form-field class="w-100">
            <input matInput [(ngModel)]="filterED.name" name="filterED" placeholder="ED" [matAutocomplete]="auto" #edName>
            <button mat-button *ngIf="filterED.id" matSuffix mat-icon-button aria-label="Clear" (click)="resetFilterEd()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete"
                              (optionSelected)="onAutoComplete()"
                              [panelWidth]="'400px'">
              <mat-option *ngFor="let option of EDs | filterEd:edName.value" [value]="option">
                {{option.name}} <span class="mat-small">({{option.path}})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-3" *ngIf="!hideRoleFilter">
          <mat-form-field  class="w-100">
            <mat-select #filterRolesSelect [(ngModel)]="filterRoles" name="filterRoles" placeholder="Role" multiple>
              <mat-option *ngFor="let role of userRoles" [value]="role.value">{{role.viewValue}}</mat-option>
            </mat-select>
            <button mat-button *ngIf="filterRoles.length" matSuffix mat-icon-button aria-label="Clear"
                    (click)="filterRoles=[]">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput type="text" placeholder="E-mail" name="filterEmail" [(ngModel)]="filterEmail" >
            <button mat-button *ngIf="filterEmail" matSuffix mat-icon-button aria-label="Clear" (click)="filterEmail=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <button mat-raised-button color="accent" aria-label="search" (click)="fetchData()" class="w-100">
            <mat-icon>search</mat-icon>
            Search
          </button>
        </div>
      </div>
    </form>

    <ngx-datatable
            class="material mat-typography "
            [rows]="users"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [rowClass]="getRowClass"
            [scrollbarH]="true"
            [externalPaging]="true"
            [limit]="page.size"
            [count]="page.totalElements"
            [offset]="page.pageNumber"
            (page)='setPage($event)'
    >

      <ngx-datatable-column name="ID" [width]="'30px'" *ngIf="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span>{{row.id}}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Name">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span>{{row.first_name}} {{row.last_name}}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Login" [minWidth]="250">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <a mat-button (click)="editUser(row)">{{row.email}}</a>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Role" *ngIf="!hideRoleColumn">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span>{{row.role.role}}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="ED" *ngIf="!hideEDColumn">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span> {{row.ed ? row.ed.name : ''}}</span>
        </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column name="Business" *ngIf="!hideBusinessColumn">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span>{{row.business ? row.business.name : ''}}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Suspended">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span>{{row.suspended ? 'Yes' : 'No'}} </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Created At">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span>{{row.created_at | formatDate | date:'medium' }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Last Login">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span>{{row.last_login | formatDate | date:'medium' }}</span>
        </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column name="Actions" frozenRight="true" width="65">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

          <button mat-icon-button aria-label="actions" [matMenuTriggerFor]="menu" 
            [disabled]="row.role_id < currentUserRole">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editUser(row)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="delUser(row)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>

        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </mat-card-content>

</mat-card>
