import {IDefinition} from "tripetto";
import {Export} from "tripetto-collector";
import {INode} from "@tripetto/map/lib/nodes/interface";
import {ITimelineEvent} from "../components/timeline/timelineEvent.interface";

export class User extends Object {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
  role_id?: number;
  role?: Role;
  ed_id?: number;
  ed?: ED;
  business_id?: number;
  business?: Business;
  suspended?: boolean;
  activated?: boolean;
  timezone?: string;
  predecessor_id?: number;
}

export interface Role {
  id?: number;
  role?: string;
}

export interface ED {
  id?: number;
  name?: string;
  business_name?: string;
  path?: string;
  city?: string;
  county?: string;
  state?: string;
  logo_url?: string;
  contact_email?: string;
  contact_phone?: string;
}

export interface Business {
  id?: number;
  name?: string;
  ed_id: number;
}

export interface Survey {
  id?: number;
  ed_id?: number;
  status?: string;
  survey_definition_id?: number;
  survey_definition?: SurveyDefinition;
  survey_response?: SurveyResponse;
  responses?: SurveyResponse[];
  title?: string;
  video_link?: string;
  description?: string;
  start_date?: string;
  end_date?: string;
  users?: User;
  created_at?: string;
  updated_at?: string;
  timeline_events?: ITimelineEvent[];
  results_compiled_at?: string;
  survey_takers_count?: number;
  submitted_count?: number;
  safe_harbor_count?: number;
  show_businesses_list_in_results?: boolean;
  mandatory_occupation_ids?: number[];
  cover_page_text?: string;
  results_pdf_url?: string;
  infographic_pdf_url?: string;
  parent_survey_id?: number;
  custom_survey_invitation?: string;
}

export interface SurveySection {
  definition?: IDefinition;
  is_compensation_survey?: boolean;
}

export interface SurveyDefinition {
  id?: number;
  survey_definition_template_id?: number;
  survey_id?: number;
  no_prefill?: boolean;
  title?: string;
  definition?: SurveySection[];
  industries?: Industry[];
  occupations?: Occupation[];
  is_live?: boolean;
  is_template?: boolean;
  original?: SurveyDefinition;
  edited?: boolean;
  survey?: Survey;
  filters?: Array<string>;
  excluded_questions?: Array<string>;
}

export interface SurveyResponseStatus {
  code: number;
  label: string;
}

export interface SurveyResponse {
  id?: number;
  survey_id?: number;
  survey?: Survey;
  user_id?: number;
  survey_definition_id?: number;
  response?: SurveyResponsePayload;
  previousAnswers?: [];
  submitted?: boolean;
  created_at?: string;
  updated_at?: string;
  created_by?: string;
  updated_by?: string;
  survey_definition?: IDefinition;
  user?: User;
  status?: SurveyResponseStatus,
  complete?: any,
  progress?: any,
  exclude_from_results?: boolean;
}

export interface Occupation {
  id?: number;
  title?: string;
  description?: string;
  industry_id?: number;
  industry?: Industry;
  selected: boolean;
  state: SurveySectionResponse;
}

export interface SurveyResponsePayload {
  complete?: boolean;
  progress?: number;
  activeSection?: any;
  sections?: SurveySectionResponse[];
}

export interface SurveySectionResponse {
  complete?: boolean;
  is_compensation_survey?: boolean;
  fields?: Export.IFields;
  industries?: Industry[];
  activeOccupation?: Occupation;
}

export interface Industry {
  id?: number;
  title?: string;
  occupations?: Occupation[];
}

export interface SurveyResultsQuestion extends INode {
  conditions?: string[];
  results?: any;
}

export interface SurveyResultsSection {
  name: string;
  questions?: SurveyResultsQuestion[];
  industries?: Industry[];
  is_compensation_survey?: boolean;
}

export interface SurveyResults {
  sections?: SurveyResultsSection[];
  businesses?: Business[];
}
