import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {EDMemberGuard, EDOwnerGuard} from '../../app.guard';
import {PendingChangesGuard} from '../../app.guard';
import {UsersComponent} from './users/users.component';
import {SurveysComponent} from './surveys/surveys.component';
import {ListSurveysComponent} from './surveys/list-surveys/list-surveys.component';
import {StartSurveyComponent} from './surveys/start-survey/start-survey.component';
import {TakeSurveyComponent} from './survey/take-survey/take-survey.component';
import {SurveyResultsComponent} from './survey/survey-results/survey-results.component';
import {ResultsComponent} from './results/results.component';
import {SurveyPrintResultsComponent} from './survey/survey-results/survey-print-results/survey-print-results.component';
import {SurveyResponsePrintComponent} from './survey/survey-results/survey-response-print/survey-response-print.component';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'dashboard', component: DashboardComponent},
  // {path: 'stats', component: StatisticsComponent},
  {path: 'results', component: ResultsComponent},
  {path: 'results/:survey_id', component: SurveyResultsComponent},
  {path: 'results/:survey_id/print', component: SurveyPrintResultsComponent},
  {path: 'users', component: UsersComponent},
  {path: 'surveys', component: SurveysComponent},
  {path: 'surveys/list', component: ListSurveysComponent},
  {
    path: 'surveys/start',
    component: StartSurveyComponent,
    canActivate: [EDOwnerGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {path: 'survey/:survey_id', component: TakeSurveyComponent},
  {path: 'survey/:survey_id/take', component: TakeSurveyComponent},
  {path: 'survey/:survey_id/results', component: SurveyResultsComponent},
  {path: 'survey/:survey_id/survey-response/:response_id/print', component: SurveyResponsePrintComponent},
  {
    path: 'settings',
    canActivateChild: [EDMemberGuard],
    loadChildren: './settings/settings.module#SettingsModule'
  },
  {path: 'settings', component: StatisticsComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EDRoutingModule {
}
