<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<div class="mt-4">
  <mat-card-content>
    <app-viewer-dashboard-survey
      class="mb-4"
      *ngFor="let survey of surveys"
      [survey]="survey"
    ></app-viewer-dashboard-survey>
  </mat-card-content>
</div>
