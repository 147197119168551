import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageComponent} from '../../../../component/message';
import {SurveyService} from '../../../../core/survey.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
  Industry,
  Occupation,
  SurveyDefinition,
  SurveyResponse,
  SurveyResponsePayload,
  SurveySection,
  SurveySectionResponse
} from '../../../../model/common';
import {debounceTime, filter, finalize, map} from 'rxjs/operators';
import {CollectorComponent} from '../../../../tripetto/collector/collector.component';
import {ICollectorChangeEvent} from 'tripetto-collector/lib/events';
import {Export, IDefinition, Import} from 'tripetto-collector';
import {AuthApiService} from '../../../../core/auth-api.service';
import {MatSelectionList} from '@angular/material';
import {DialogService} from '../../../../component/dialog';
import {SelectIndustriesAndOccupationsComponent} from "./select-industries-and-occupations/select-industries-and-occupations.component";
import {IndustriesService} from "../../../../core/industries.service";
import { isArray } from 'tripetto';


@Component({
  selector: 'app-take-survey',
  templateUrl: './take-survey.component.html',
  styleUrls: ['./take-survey.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeSurveyComponent implements OnInit, OnDestroy {

  @ViewChild('message') message: MessageComponent;
  @ViewChild('collector') collector: CollectorComponent;
  @ViewChild('surveyQuestionsColumn') surveyQuestionsWrapper: ElementRef;
  @ViewChild('saveBtn') saveBtn: HTMLButtonElement;

  @ViewChild('industriesSetupContainer') industriesSetupContainer: HTMLElement;
  @ViewChild('industriesFilter') industriesFilter: HTMLInputElement;

  @ViewChildren(MatSelectionList) occupationLists: QueryList<MatSelectionList>;
  @ViewChildren('occupationLink') occupationLinks: QueryList<any>;

  @Output() onClose = new EventEmitter();

  @HostBinding('class.isModal') isModal = false;

  industriesFilterString: string;

  onSomethingChanged: BehaviorSubject<any> = new BehaviorSubject({});
  onSomethingChangedSub: Subscription;

  activeSection: SurveySection;
  activeSectionState: SurveySectionResponse;

  surveyResponse: SurveyResponse;

  collectorChangedSub: Subscription;
  collectorFinishedSub: Subscription;
  routeSub: Subscription;

  lastSavedTimestamp = null;
  occupationDescriptionsCache = {};

  mandatoryOccupationIDs = [];
  incompleteOccupations = [];

  public _surveyDefinition: SurveyDefinition;
  @Input() set surveyDefinition(surveyDefinition) {
    this._surveyDefinition = surveyDefinition;
    this._surveyDefinition.definition = this._surveyDefinition.definition.map(section => {
      section.is_compensation_survey = section.is_compensation_survey || false;
      return section;
    });
    // this.activateFirstSection();
  }

  get surveyDefinition(): SurveyDefinition {
    return this._surveyDefinition;
  }

  surveyProgress: number = 0;

  public readOnly: Boolean = false;
  public prefilled: Boolean = false;

  filteredIndustries: BehaviorSubject<Industry[]> = new BehaviorSubject([]);

  constructor(private dialog: DialogService,
              private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private router: Router,
              private surveyService: SurveyService,
              private hostRef: ElementRef,
              private auth: AuthApiService,
              private industriesService: IndustriesService
  ) {
  }

  init() {
    if (this.collector) {
      this.collectorChangedSub = this.collector.changed
        .pipe(filter((ev: ICollectorChangeEvent, i) => {
          let filter = (!ev || !ev.storyline);
          // console.log('pass event', !filter, ev);
          return !filter;
        }))
        .pipe(debounceTime(200))
        .pipe(map((ev: ICollectorChangeEvent) => {
          this.onCollectorChanged(ev);
          return ev;
        }))
        .subscribe();

      this.collectorFinishedSub = this.collector.finished.subscribe(r => {
        this.onCollectorSectionFinished();
      });
    }

    this.activateFirstSection();
  }

  activateFirstSection() {
    if (this.surveyDefinition && this.surveyDefinition.definition.length) {
      this.activateSection(this.surveyDefinition.definition[0]);
    }
  }

  ngOnInit() {
    this.onSomethingChangedSub = this.onSomethingChanged
      .pipe(map(() => this.calcSurveyProgress()))
      .pipe(debounceTime(3000))
      .pipe(map(() => this.saveSurveyState()))
      .subscribe();

    this.routeSub = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('survey_id')) {
        this.message.renderApiError();
        this.surveyService.takeSurvey(params['survey_id']).subscribe(
          r => {
            this.initFromSurveyResponse(<SurveyResponse>r);
          },
          e => {
            this.message.renderApiError(e);
          }
        );
      }
    });
  }

  initFromSurveyResponse(r: SurveyResponse) {
    this.init();
    this.surveyResponse = <SurveyResponse>r;

    this.surveyResponse.response = this.surveyResponse.response || <SurveyResponsePayload>{activeSection: 0};
    this.surveyResponse.response.sections = this.surveyResponse.response.sections || <SurveySectionResponse[]>[];

    this.mandatoryOccupationIDs = this.surveyResponse.survey.mandatory_occupation_ids || [];

    if (this.surveyResponse.response) {
      this.surveyProgress = this.surveyResponse.response.progress;
    }

    this.surveyDefinition = this.surveyResponse.survey.survey_definition;
    this.surveyDefinition.title = this.surveyResponse.survey.title;

    if (this.surveyResponse.previousAnswers) {
      this.prefilled = true;
    }

    this.surveyDefinition.definition.forEach((section: SurveySection, index) => {
      if (this.surveyResponse.response.sections[index]
        && (this.surveyResponse.response.sections[index]['fields'] || this.surveyResponse.response.sections[index]['industries'])) {
        // we have a response filled in for this survey => no prefilling of the section
        return;
      }

      if (!section.is_compensation_survey) {
        if (this.surveyResponse.previousAnswers) {
          this.surveyResponse.response.sections[index] = this.loadPreviousAnswers(section.definition);
        } else {
          this.surveyResponse.response.sections[index] = <SurveySectionResponse>{};
        }
      } else {
        if (!this.surveyResponse.response.sections[index]
          || isArray(this.surveyResponse.response.sections[index])) {
          this.surveyResponse.response.sections[index] = <SurveySectionResponse>{};
          this.surveyResponse.response.sections[index].industries = this.surveyDefinition.industries;
        }

        if (this.surveyResponse.previousAnswers) {
          this.loadPreviousCompensationAnswers(section.definition, this.surveyResponse.response.sections[index].industries);
        }

        this.surveyResponse.response.sections[index].is_compensation_survey = true;
        this.initActiveOccupation(this.surveyResponse.response.sections[index]);
        this.initMandatoryOccupations(this.surveyResponse.response.sections[index]);
      }
    });

    if (this.surveyResponse.submitted && this.auth.isUser()) {
      this.readOnly = true;
    }

    const sectionIndex = this.surveyResponse.response ? this.surveyResponse.response['activeSection'] : 0;
    
    if(sectionIndex==0){
      setTimeout(() => {
        this.activateFirstSection()
      }, 1);
    } else {
      this.activateSection(this.surveyDefinition.definition[sectionIndex]);  
    }
  }

  loadPreviousAnswers(definitionSection): SurveySectionResponse {
    let responseSection = JSON.parse(JSON.stringify(this.createEmptyResponseForDefinition(definitionSection)));

    if (responseSection['fields'] && responseSection['fields']['fields']) {
      for (let question of responseSection['fields']['fields']) {
        let name = question['name'];
        if (this.surveyResponse.previousAnswers.hasOwnProperty(name)
        && this.surveyResponse.previousAnswers[name]['type'] === question['type']) {
          question['value'] = this.surveyResponse.previousAnswers[name]['value'];
          question['string'] = this.surveyResponse.previousAnswers[name]['string'];
          if (this.surveyResponse.previousAnswers[name]['reference']) {
            question['reference'] = this.surveyResponse.previousAnswers[name]['reference'];
          }
        }
      }
    }

    return <SurveySectionResponse>responseSection;
  }

  loadPreviousCompensationAnswers(definitionSection: IDefinition, industries) {
    let responseTemplate = JSON.parse(JSON.stringify(this.createEmptyResponseForDefinition(definitionSection)));
    let previousOccupations = this.surveyResponse.previousAnswers['occupations'] || [];

    for (let industry of industries) {
      for (let occupation of industry.occupations) {
        if (previousOccupations.hasOwnProperty(occupation.id)) {
          occupation.selected = true;
          let responseSection = JSON.parse(JSON.stringify(responseTemplate));
          responseSection['complete'] = false;
          let occupationAnswers = previousOccupations[occupation.id];

          if (responseSection['fields'] && responseSection['fields']['fields']) {
            for (let question of responseSection['fields']['fields']) {
              let name = question['name'];
              if (occupationAnswers.hasOwnProperty(name)) {
                question['value'] = occupationAnswers[name]['value'];
                question['string'] = occupationAnswers[name]['string'];
                if (occupationAnswers[name]['reference']) {
                  question['reference'] = occupationAnswers[name]['reference'];
                }
              }
            }
          }
          occupation['state'] = responseSection;
        }
      }
    }
  }

  createEmptyResponseForDefinition(definitionSection: IDefinition): SurveySectionResponse {
    let responseSection = <SurveySectionResponse>{};
    this.collector.definition = definitionSection;
    let instance = this.collector.instance;
    let fields = instance ? Export.fields(instance) : null;
    responseSection['fields'] = fields;

    return responseSection;
  }

  ngOnDestroy() {

    this.saveSurveyState();

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.collectorChangedSub) {
      this.collectorChangedSub.unsubscribe();
    }
    if (this.collectorFinishedSub) {
      this.collectorFinishedSub.unsubscribe();
    }
    if (this.onSomethingChangedSub) {
      this.onSomethingChangedSub.unsubscribe();
    }
  }

  isPreviewMode() {
    return !(this.surveyResponse && this.surveyResponse.id);
  }

  onCollectorChanged(ev: ICollectorChangeEvent) {
    this.saveActiveSectionState(ev);
  }

  onCollectorSectionFinished() {
    this.gotoNextSection();
  }

  exportActiveSectionState(ev?: ICollectorChangeEvent): SurveySectionResponse {
    let instance = this.collector.instance;
    let storyline = this.collector.storyline;

    let fields = instance ? Export.fields(instance) : null;

    let state = this.activeSectionState;
    if (!state) return null;

    if (state.is_compensation_survey) {
      if (state.activeOccupation && state.activeOccupation) {
        state.activeOccupation.state = state.activeOccupation.state || {};

        if (ev) {
          state.activeOccupation.state = {
            fields: fields,
            complete: storyline.isFinishable
          };
        }

      }
    } else {
      if (ev) {
        state.fields = fields;
        state.complete = storyline ? storyline.isFinishable : false;
      }
    }

    return {...state};
  }

  getSectionIndex(section: SurveySection) {
    if (!section || !this.surveyDefinition) {
      return null;
    }
    return this.surveyDefinition.definition.indexOf(section);
  }

  getActiveSectionIndex() {
    return this.getSectionIndex(this.activeSection);
  }

  canStepBack() {
    if (!this.surveyDefinition) {
      return false;
    }
    let i = this.getActiveSectionIndex() - 1;
    return true && this.surveyDefinition.definition[i];
  }

  gotoPrevSection() {
    if (!this.canStepBack()) {
      return;
    }

    if (this.activeSectionState && this.activeSectionState.activeOccupation) {
      if (this.previousOccupation()) {
        this.resetSurveyQuestionsScrollPosition();
        return;
      }
    }

    let i = this.getActiveSectionIndex() - 1;
    if (this.surveyDefinition.definition[i]) {
      this.activateSection(this.surveyDefinition.definition[i]);
    }
    this.resetSurveyQuestionsScrollPosition();
  }

  gotoNextSection() {
    if (this.activeSectionState && this.activeSectionState.activeOccupation) {
      if (this.nextOccupation()) {
        this.resetSurveyQuestionsScrollPosition();
        return;
      }
      ;
    }

    if (this.surveyDefinition.definition[this.getActiveSectionIndex() + 1]) {
      this.activateSection(this.surveyDefinition.definition[this.getActiveSectionIndex() + 1]);
    } else {
      if (!this.surveyResponse) {
        return;
      }
      this.activateSection(null);
    }
    this.resetSurveyQuestionsScrollPosition();
  }

  resetSurveyQuestionsScrollPosition() {
    this.surveyQuestionsWrapper.nativeElement.scrollTop = 0;
  }

  saveSectionState(sectionIndex, state) {
    if (this.isPreviewMode() || this.readOnly) {
      return;
    }

    let response = this.surveyResponse.response;

    // console.log('Saving section state', sectionIndex, state);

    response = response || {activeSection: null, progress: 0, sections: []};

    if (!Array.isArray(response.sections) || !response.sections.length) {
      response.sections = [];
      this.surveyDefinition.definition.forEach(() => {
        response.sections.push(<SurveySectionResponse>{});
      });
    }

    response.sections[sectionIndex] = state;
    response.activeSection = this.getActiveSectionIndex();
    response.progress = this.surveyProgress;

    response.complete = response.sections.filter((section: SurveySectionResponse) => section.complete).length == response['sections'].length;

    this.surveyResponse.response = response;
    this.onSomethingChanged.next(null);
  }

  saveSurveyState() {
    if (this.isPreviewMode() || this.readOnly) {
      return;
    }

    this.message.renderApiError();
    this.saveBtn.disabled = true;
    this.surveyService.saveState(this.surveyResponse).pipe(finalize(() => {
      this.saveBtn.disabled = false;
    })).subscribe(() => {
      this.lastSavedTimestamp = Date.now();
    }, (e) => this.message.renderApiError(e));
  }

  getStateForSectionIndex(index): SurveySectionResponse {
    if (!this.surveyResponse) {
      return null;
    }
    let response = this.surveyResponse.response;
    if (!response) {
      return null;
    }
    if (!Array.isArray(response['sections'])) {
      return null;
    }
    return response['sections'][index];
  }

  hasSectionQuestions(sectionDefinition) {
    if (!sectionDefinition.hasOwnProperty('clusters')) {
      return false;
    }

    for (let i = 0; i < sectionDefinition.clusters.length; i++) {
      const cluster = sectionDefinition.clusters[i];
      if (!cluster.hasOwnProperty('nodes')) {
        continue;
      }
      for (let j = 0; j < cluster.nodes.length; j++) {
        const node = cluster.nodes[j];
        if (node.hasOwnProperty('block')) {
          return true;
        }
      }
    }
    return false;
  }

  isSectionComplete(index) {
    let state = this.getStateForSectionIndex(index);

    let hasSectionQuestions = true;
    if (this.surveyResponse) {
      const sectionDefinition = this.surveyResponse.survey.survey_definition.definition[index].definition;
      hasSectionQuestions = this.hasSectionQuestions(sectionDefinition);
    }

    return (state && state['complete']) || !hasSectionQuestions;
  }

  saveActiveSectionState(ev?: ICollectorChangeEvent) {
    this.saveSectionState(this.getActiveSectionIndex(), this.exportActiveSectionState(ev));
  }

  activateSection(section: SurveySection) {
    if (this.activeSection == section) {
      return;
    }

    // save the current section state before switching
    if (this.activeSection) {
      this.saveActiveSectionState();
    }

    if (section === null) {
      this.activeSection = null;
      this.activeSectionState = null;
      return;
    }

    let sectionIndex = this.getSectionIndex(section);

    this.activeSection = section;
    this.activeSectionState = this.getStateForSectionIndex(sectionIndex);

    if (this.activeSection.is_compensation_survey && this.surveyResponse && this.surveyResponse.id) {
      if (this.activeSectionState.activeOccupation) {
        this.activateOccupation(this.activeSectionState.activeOccupation);
      } else {
        this.initCollector(null);
        if (!this.readOnly || !this.isPreviewMode()) {
          this.selectIndustriesAndOccupations();
        }
      }
    } else {
      this.initCollector(section.definition, this.activeSectionState);
    }

    this.resetSurveyQuestionsScrollPosition();

  }

  initCollector(definition: IDefinition, state?: SurveySectionResponse) {
    // console.log('initCollector', definition, state);

    this.collector.mode = 'progressive';
    this.collector.set('enumerators', true);

    if (this.isPreviewMode()) {
      this.collector.set('preview', true);

    }

    this.collector.definition = definition;

    if (definition && state && state['fields']) {
      //todo importing values for non-existing fields breaks the import, filter the data beforehand
      //todo show error on fail!
      let importStatus = Import.fields(this.collector.instance, state['fields']['fields']);
      console.log('Fields import:', importStatus);
    }

  }

  calcSurveyProgress() {
    if (!this.surveyResponse || !this.surveyResponse.response || !this.surveyResponse.response['sections']) {
      return 0;
    }

    let sections = <Array<any>>this.surveyResponse.response['sections'];
    if (!sections.length) {
      return 0;
    }

    let completedSections = 0;
    let totalSections = 0;
    sections.forEach((section: SurveySectionResponse, index) => {

      if (section.is_compensation_survey) {
        let completedOccupationSections = 0;
        let totalOccupationSections = 0;

        if (section.industries && isArray(section.industries))
        section.industries.forEach((industry: Industry) => {
          industry.occupations.forEach((occupation: Occupation) => {
            if (!occupation.selected) {
              return;
            }
            totalOccupationSections++;
            if (occupation.state && occupation.state.complete) {
              completedOccupationSections++;
            }
          });
        });

        section.complete = totalOccupationSections && totalOccupationSections == completedOccupationSections;

        totalSections += totalOccupationSections;
        if (!totalOccupationSections) {
          totalSections++;
        }

        completedSections += completedOccupationSections;
      } else {
        totalSections++;
        const sectionDefinition = this.surveyResponse.survey.survey_definition.definition[index].definition;

        if (section.complete || !this.hasSectionQuestions(sectionDefinition)) {
          completedSections++;
        }
      }

    });

    this.surveyProgress = Math.round((completedSections / totalSections) * 100);
    return this.surveyProgress;
  }

  activateOccupation(occupation: Occupation, industry?: Industry) {
    this.activeSectionState.activeOccupation = occupation;

    if (!this.occupationDescriptionsCache[occupation.id]) {
      this.industriesService.getOccupation(occupation.id).subscribe(r => {
        this.occupationDescriptionsCache[occupation.id] = r['description'];
      }, e => {
        this.message.renderApiError(e);
      });
    }

    let state = occupation.state;
    this.initCollector(this.activeSection.definition, state);
    this.saveActiveSectionState();
  }

  isActiveOccupation(occupation, industry?) {
    if (!this.activeSectionState.is_compensation_survey || !this.activeSectionState.activeOccupation) {
      return false;
    }
    return this.activeSectionState.activeOccupation.id == occupation.id;
  }

  isOccupationComplete(occupation: Occupation) {
    return occupation.state && occupation.state.complete;
  }

  isCollectorHidden() {
    if (!this.activeSection) return true;
    return !this.collector.instance || (this.activeSectionState && this.activeSectionState.is_compensation_survey && !this.activeSectionState.activeOccupation);
  }


  isCompensationSection(index) {
    const isCompensationSection = this.surveyResponse.survey.survey_definition.definition[index].is_compensation_survey;

    if (isCompensationSection) {
      this.incompleteOccupations = [];
      for (let industry of this.surveyResponse.response.sections[index].industries) {
        for (let occupation of industry.occupations) {
          if (occupation.selected) {
            if (occupation.state && !occupation.state.complete) {
              let i = 1; let indexes = '';
              if(occupation.state.fields)
                for (let field of occupation.state.fields.fields) {
                  if (field.value == null) {
                    indexes += i + ', ';
                  }
                  i++;
                }
              indexes = indexes.slice(0, -2);
              this.incompleteOccupations.push(occupation.title + ' (question number(s): ' + indexes + ')');
            } else if (!occupation.state) {
              this.incompleteOccupations.push(occupation.title + ' (all questions incomplete)');
            }
          }
        }
      }
    }

    return isCompensationSection;
  }

  industryHasSelectedOccupation(industry: Industry): boolean {
    if (!industry || !industry.occupations || !industry.occupations.length) {
      return false;
    }

    for (let occupation of industry.occupations) {
      if (occupation.selected) {
        return true;
      }
    }

    return false;
  }

  onIndustriesFilter(value?: string) {
    this.filteredIndustries.next(this.activeSectionState.industries.filter((industry: Industry) => {
      if (!value || !value.length) {
        return true;
      }
      return industry.title.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1;
    }));
  }

  toggleIndustriesSetupContainer(container) {
    container.hidden = !container.hidden;
    if (!container.hidden) {
      this.onIndustriesFilter(this.industriesFilterString);
    }
  }

  clearOccupationLevels(occupation) {
    occupation.levels = [];
    this.saveActiveSectionState();
  }

  canSubmit() {
    return this.surveyProgress == 100 && !this.surveyResponse.submitted && !this.isPreviewMode() && !this.readOnly;
  }

  submit(submitButton: HTMLButtonElement) {
    if (!this.canSubmit()) {
      return;
    }

    this.dialog.confirm({
      title: 'Confirm submit',
      message: 'You are about to submit your survey. No changes are possible once the survey response is submitted. Do you confirm?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, submit the results'
    }).afterClosed().subscribe(r => {
      if (r !== true) {
        return;
      }

      this.message.renderApiError();
      submitButton.disabled = true;
      this.surveyService.submit(this.surveyResponse)
        .pipe(finalize(() => {
          submitButton.disabled = false;
        }))
        .subscribe(
          r => {
            this.surveyResponse = <SurveyResponse>r;
            this.readOnly = true;
            this.message.renderSuccess({'message': 'Survey response successfully submitted! You will be notified by e-mail when the results are published.'});
          },
          e => {
            this.message.renderApiError(e);
          }
        );
    });
  }

  onCloseClicked() {
    this.onClose.emit();
  }

  save(saveBtn: HTMLButtonElement) {
    this.saveSurveyState();
  }


  nextOccupation() {
    let navigated = false;

    if (this.activeSectionState && this.activeSectionState.activeOccupation) {
      const occupationLinks = this.occupationLinks.toArray();

      occupationLinks.find((link, i) => {
        if (link['color'] !== undefined) {
          if (occupationLinks[i + 1]) {
            occupationLinks[i + 1]._elementRef.nativeElement.click();
            navigated = true;
          }
          return true;
        }
      });
    }

    return navigated;
  }

  previousOccupation() {
    let navigated = false;

    if (this.activeSectionState && this.activeSectionState.activeOccupation) {
       const occupationLinks = this.occupationLinks.toArray();

      if (occupationLinks.length < 2) {
        return false;
      }

      occupationLinks.find((link, i) => {
        if (link['color'] !== undefined) {
          if (occupationLinks[i - 1]) {
            occupationLinks[i - 1]._elementRef.nativeElement.click();
            navigated = true;
          }
          return true;
        }
      });
    }

    return navigated;
  }

  selectIndustriesAndOccupations() {
    const dialogRef = this.dialog.open(SelectIndustriesAndOccupationsComponent, {
      panelClass: 'dialog-lg',
      maxHeight: '100vh',
      minHeight: '100vh'
    });

    dialogRef.componentInstance.surveySection = this.activeSectionState;
    dialogRef.componentInstance.mandatoryOccupationIDs = this.mandatoryOccupationIDs;

    dialogRef.afterClosed().subscribe(() => {
      if (this.activeSectionState && !this.activeSectionState.activeOccupation) {
        let firstOccupation = this.occupationLinks && this.occupationLinks.first;
        if (firstOccupation) {
          firstOccupation._elementRef.nativeElement.click();
        }
      }
      this.setActiveOccupation(this.activeSectionState);
    });

    if (this.changeDetectorRef) {
      this.changeDetectorRef.detectChanges();
    }
  }

  setActiveOccupation(section) {
    if (!section || !section.activeOccupation) {
      return;
    }
    const id = section.activeOccupation.id;

    // deselect active occupation if deselected in the industries+occupations list
    loop1:
    for (const industry of section.industries) {
      for (const occupation of industry.occupations) {
        if (id === occupation.id) {
          if (!occupation.selected) {
            section.activeOccupation = null;
            break loop1;
          }
        }
      }
    }

    // set first available occupation as active occupation if there are any in the industries/occupations list
    loop2:
    if (!section.activeOccupation) {
      for (const industry of section.industries) {
        for (const occupation of industry.occupations) {
          if (occupation.selected) {
            this.activateOccupation(occupation);
            break loop2;
          }
        }
      }
    }
    this.saveActiveSectionState();

  }

  initActiveOccupation(section) {
    if (!section || !section.activeOccupation) {
      return;
    }
    const id = section.activeOccupation.id;

    if (section.industries && isArray(section.industries))
    section.industries.forEach((industry: Industry) => {
      industry.occupations.forEach((occupation: Occupation) => {
        if (id === occupation.id) {
          occupation.selected = true;
        }
      });
    });
  }


  initMandatoryOccupations(section) {
    if (!section) {
      return;
    }

    const ids = {};
    if (this.mandatoryOccupationIDs) {
      this.mandatoryOccupationIDs.forEach(id => ids[id] = id);
    }

    if (section.industries && isArray(section.industries))
    section.industries.forEach((industry: Industry) => {
      industry.occupations.forEach((occupation: Occupation) => {
        if (ids[occupation.id]) {
          occupation.selected = true;
        }
      });
    });
  }

}
