import {Component, EventEmitter, Input, OnDestroy, OnInit, OnChanges, Output, ViewChild, SimpleChanges} from '@angular/core';
import {MessageComponent} from "../../../../../component/message";
import {MatPaginator, MatSort, MatSortable, MatTableDataSource} from "@angular/material";
import {User} from "../../../../../model/common";
import {SurveyDefinition} from "../../../../../model/common";
import {SelectionModel} from "@angular/cdk/collections";
import {SurveyService} from '../../../../../core/survey.service';
import {EdService} from "../../../../../core/ed.service";
import {DialogService} from "../../../../../component/dialog";
import {InviteUsersDialogComponent} from "./invite-users-dialog/invite-users-dialog.component";
import {AddSuccessorDialogComponent} from './add-successor-dialog/add-successor-dialog.component';
import {debounceTime, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-step-invite-users',
  templateUrl: './step-invite-users.component.html',
  styleUrls: ['./step-invite-users.component.scss']
})
export class StepInviteUsersComponent implements OnInit, OnChanges, OnDestroy {

  @Input() message: MessageComponent;
  @Input() surveyTemplate: SurveyDefinition;
  @Input() selectedUsers: Observable<User[]>;
  @Input() surveyID;
  @Input() selectAll: boolean = false;

  @Output() onUsersSelected = new EventEmitter();
  @Output() onUsersChanged = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['select', 'first_name', 'last_name', 'email', 'business', 'surveyed_count', 'add_successor', 'successor_of'];
  dataSource = new MatTableDataSource<User>();
  selection = new SelectionModel<User>(true, []);
  showAllUsers = true;
  filterValue = '';

  businessUsers: Array<User> = [];
  usersWithResponse: Array<User> = [];
  preselectedUsers: Array<User> = [];

  constructor(
    private edService: EdService,
    private dialog: DialogService,
    private surveyService: SurveyService,
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (user: User, sortHeaderId: string) => {
      if (sortHeaderId === 'business') {
        return user[sortHeaderId]['name'];
      } else if (sortHeaderId === 'select') {
        return this.selection.isSelected(user) ? 1 : 0;
      } else {
        return user[sortHeaderId];
      }
    };
    const that = this;
    this.dataSource.filterPredicate = (user: User, filter: string) => {
      let haystack = [user.first_name, user.last_name, user.email, user.business.name].join(' ').toLowerCase();
      return (haystack.search(filter) >= 0 || filter == 'placeholder') && (that.showAllUsers || user['has_response']);
    };

    this.selection.changed.pipe(debounceTime(500)).subscribe(() => {
      this.onUsersSelected.emit(this.selection.selected);
    });
    this.getBusinessUsers();
  }

  onShowAllUsersToggle() {
    // filter function is not triggered by Angular Material if filter value is empty
    this.applyFilter('placeholder');
  }

  onFilterChanged() {
    this.applyFilter(this.filterValue);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('surveyTemplate') || changes.hasOwnProperty('selectedUsers')) {
      this.getSurveyRespondents();
    }

    if (changes.hasOwnProperty('surveyTemplate')) {
      this.getPreviousSelectedUsers();
    }
  }

  getSurveyRespondents() {
    if ((this.surveyTemplate
      && !this.surveyTemplate.no_prefill
      && !this.surveyTemplate.is_template
      && this.surveyTemplate.survey.id) || (this.surveyID && !this.surveyTemplate )) {
      this.surveyService.getUsersWithResponse(this.surveyTemplate ? this.surveyTemplate.survey.id : this.surveyID).subscribe(
        users => {
          this.usersWithResponse = <User[]>users;
          this.setBusinessUserResponse();
      });
    } else {
      this.usersWithResponse = [];
    }
  }

  getPreviousSelectedUsers() {
    if (this.surveyTemplate
      && !this.surveyTemplate.is_template
      && this.surveyTemplate.survey.id) {
      this.surveyService.getPreviousSelectedUsers(this.surveyTemplate.survey.id).subscribe(
        users => {
          this.preselectedUsers = <User[]>users;
          this.preselectUsers();
      });
    } else {
      this.preselectedUsers = [];
    }
  }

  getBusinessUsers() {
    this.edService.businessUsers().subscribe(r => {
      this.dataSource.data = r['data'];
      this.businessUsers = JSON.parse(JSON.stringify(r['data']));

      if (this.selectedUsers) {
        this.selectedUsers.subscribe(r => {

          let userIDs = {};
          r.map(u => userIDs[u.id] = u);

          this.dataSource.data.forEach(u => {
            if (!userIDs[u.id]) return;
            this.selection.select(u);
          });

          this.sort.sort(<MatSortable>({id: 'select', start: 'desc'}));
          this.dataSource.sort = this.sort;
        });
        
        this.dataSource.data = r['data'];
      }

      this.setBusinessUserResponse();

    }, e => {
      this.message.renderApiError(e);
    });
  }

  setBusinessUserResponse() {
    for (let businessUser of this.businessUsers) {
      const index = this.usersWithResponse.findIndex(user => user.id == businessUser.id);
      if (index > -1) {
        businessUser['has_response'] = true;
      } else {
        businessUser['has_response'] = false;
      }
    }
    this.dataSource.data = this.businessUsers;
    // if(this.selectAll) {
    //   this.masterToggle()
      // this.dataSource.data.forEach(u => {
      //   this.selection.select(u);
      // });
    // }
  }

  preselectUsers() {
    this.dataSource.data.forEach(u => {
      let preselected = this.preselectedUsers.findIndex(user => user.id == u.id);
      if (preselected > -1) {
        this.selection.select(u);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  inviteUsers() {
    const dialogRef = this.dialog.open(InviteUsersDialogComponent, {
      disableClose: true,
      panelClass: 'dialog-md'
    });

    const component = dialogRef.componentInstance;
    component.onComplete.subscribe(users => {
      if (!users) return;
      // todo check for duplicate users

      this.dataSource.data = [...users, ...this.dataSource.data];
      users.forEach(u => this.selection.select(u));
      dialogRef.close();
    });
  }

  onAddSuccessorClicked($event, element) {
    $event.stopImmediatePropagation();

    const dialogRef = this.dialog.open(AddSuccessorDialogComponent, {
      disableClose: true,
      panelClass: 'dialog-md'
    });

    const component = dialogRef.componentInstance;
    component.businessUsers = this.businessUsers;
    component.predecessorUser = element;

    const that = this;
    component.onComplete.subscribe(successor => {
      if (!successor) return;

      let successorUserIndex = that.businessUsers.findIndex(user => user.id == successor.id);
      if (successorUserIndex > -1) {
        that.businessUsers[successorUserIndex].predecessor_id = element.id;
        that.businessUsers[successorUserIndex]['predecessor_name'] = element.first_name + ' ' + element.last_name;
        this.selection.toggle(component.predecessorUser)
        this.selection.toggle(that.businessUsers[successorUserIndex])
      } else {
        successor['predecessor_id'] = element.id;
        successor['predecessor_name'] = element.first_name + ' ' + element.last_name;
        that.businessUsers.unshift(successor);
      }

      let predecessorIndex = that.businessUsers.findIndex(user => user.id == element.id);
      if (predecessorIndex > -1) {
        this.businessUsers.splice(predecessorIndex, 1);
      }

      that.dataSource.data = that.businessUsers;
      this.onUsersChanged.emit(that.businessUsers);
      dialogRef.close();
    });
  }

  ngOnDestroy() {
    // this.selectionSub.unsubscribe();
    // this.selectionSub = null;
  }
}
