<h2 mat-dialog-title class="mb-2">
  Add Successor to {{predecessorUser.first_name}} {{predecessorUser.last_name}}
  <button mat-icon-button type="button" color="primary" (click)="cancelClick()" ><mat-icon>close</mat-icon></button>
</h2>
<app-message messageType="alert" *ngIf="selectedUserHasResponse">
  The selected user already has a response to this survey. If you continue, their response will be overwritten.
</app-message>
<mat-dialog-content>
  <table #usersTable class="users-table w-100" mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        Select
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-radio-button (click)="onSelectUserClicked(element)"
                          [checked]="selectedUser == element">
        </mat-radio-button>
      </td>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let element"> {{element['first_name']}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let element"> {{element['last_name']}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-Mail</th>
      <td mat-cell *matCellDef="let element"> {{element['email']}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="business">
      <th mat-header-cell *matHeaderCellDef>Business Name</th>
      <td mat-cell *matCellDef="let element"> {{element.business?.name}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="has_response">
      <th mat-header-cell *matHeaderCellDef>Has Response</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element['has_response']">check_circle</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="newUserClick()" class="mr-2"><mat-icon>person_add</mat-icon> Add New User as Successor</button>
  <button mat-raised-button color="accent" (click)="onAddSuccessorClicked()" [disabled]="!dataSource.data.length">
    Add Successor
  </button>
</mat-dialog-actions>
