<mat-card class="survey-stat survey-stat--running mat-elevation-z4">
  <mat-card-content class="mat-typography">
    <div class="survey-stat__header">
      <h2 class="survey-stat__header__title">
        {{survey.title}} <br>
      </h2>
    </div>

    <div class="row">
      <div class="col">
        <button class="w-100" color="primary" mat-raised-button (click)="viewSurvey()">View Survey</button>
      </div>
      <div class="col">
        <button class="w-100" color="accent" mat-raised-button [routerLink]="[edPath+'/results/'+survey.id]">Analyze
          Results
        </button>
      </div>
    </div>

  </mat-card-content>
</mat-card>