<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<mat-card class="survey-stat survey-stat--running mat-elevation-z4">
  <mat-card-content class="mat-typography">
    <div class="survey-stat__header">
      <h2 class="survey-stat__header__title">
        {{survey.title}} <br>
      </h2>
      <div class="survey-stat__header__meta">
        <button mat-button color="accent" matTooltip="Click to Pause the Survey" (click)="pause()"
                *ngIf="survey.status == 'running'">
          <mat-icon>play_circle_outline</mat-icon>
          In Progress
        </button>
        <button mat-button color="warn" matTooltip="Click to Start the Survey" (click)="unpause()"
                *ngIf="survey.status == 'paused'">
          <mat-icon>pause_circle_outline</mat-icon>
          Paused
        </button>
        <button mat-button color="accent" matTooltip="The survey is completed"
                *ngIf="survey.status == 'completed'">
          <mat-icon>check_circle_outline</mat-icon>
          Completed
        </button>

        <button mat-button color="primary" matTooltip="The survey has not started"
                *ngIf="survey.status == 'planning'">
          <mat-icon>access_time_outlined</mat-icon>
          Planning
        </button>
        <button mat-icon-button [matMenuTriggerFor]="surveyMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #surveyMenu>

          <button mat-menu-item (click)="viewSurvey()">
            <mat-icon>pageview</mat-icon>
            <span>View survey</span>
          </button>

          <button mat-menu-item
            (click)="edit()"
            *ngIf="isEDMember"
            [disabled]="!canEdit()">
            <mat-icon>event</mat-icon>
            <span>Edit Survey Information</span>
          </button>

          <!--<a mat-menu-item target="_blank" [routerLink]="[surveyService.getSurveyPrintPath(survey)]">-->
          <!--<mat-icon>print</mat-icon>-->
          <!--Print/Export-->
          <!--</a>-->

          <!--<button mat-menu-item (click)="edit()" [disabled]="!canEdit()">-->
          <!--<mat-icon>event</mat-icon>-->
          <!--<span>Change Title & Dates</span>-->
          <!--</button>-->

          <button mat-menu-item
            (click)="inviteUsers()"
            *ngIf="isEDSurveyManager">
            <mat-icon>person_add</mat-icon>
            <span>Survey Takers</span>
          </button>

          <button mat-menu-item
            (click)="editDefinition()"
            *ngIf="isEDOwner">
            <mat-icon>format_shapes</mat-icon>
            <span>Change Questions & Layout</span>
          </button>

          <!--<button mat-menu-item (click)="duplicate()">-->
          <!--<mat-icon>filter_none</mat-icon>-->
          <!--<span>Duplicate</span>-->
          <!--</button>-->

          <button mat-menu-item
            (click)="unpause()"
            *ngIf="survey.status == 'paused' && isEDMember">
            <mat-icon>play_circle_outline</mat-icon>
            <span>Start</span>
          </button>

          <button mat-menu-item
            (click)="pause()"
            *ngIf="survey.status == 'running' &&  isEDMember">
            <mat-icon>pause</mat-icon>
            <span>Pause</span>
          </button>

        </mat-menu>
      </div>
      <div class="survey-stat__dates">
        <div>
          <mat-icon class="align-middle">today</mat-icon>
          Open Date: <strong class="text-success">{{survey.start_date | formatDate | date}}</strong>
        </div>
        <div>
          <mat-icon class="align-middle">today</mat-icon>
          Close Date: <strong class="text-info">{{survey.end_date | formatDate | date}}</strong>
        </div>
      </div>
    </div>
    <!--todo: refactor the counters to components-->
    <div class="row mt-4 mb-4">
      <div class="col">
        <div class="survey-stat-counter">
          <div class="survey-stat-counter__title">
            Survey Takers
          </div>
          <div class="survey-stat-counter__count">
            {{surveyTakersCount}}
          </div>
        </div>
      </div>
      <!--<div class="col">-->
      <!--<div class="survey-stat-counter">-->
      <!--<div class="survey-stat-counter__title">-->
      <!--Paid Users-->
      <!--</div>-->
      <!--<div class="survey-stat-counter__count">-->
      <!--0-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <div class="col">
        <div class="survey-stat-counter">
          <div class="survey-stat-counter__title">
            In-Progress
          </div>
          <div class="survey-stat-counter__count">
            {{inProgressCount}}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="survey-stat-counter">
          <div class="survey-stat-counter__title">
            Submitted
          </div>
          <div class="survey-stat-counter__count">
            {{submittedCount}}
          </div>
        </div>
      </div>
    </div>

    <app-timeline [timelineEvents]="survey.timeline_events">
    </app-timeline>

    <div class="row">
      <div class="col">
        <button class="w-100" color="primary" mat-raised-button (click)="viewSurvey()">View Survey</button>
      </div>
      <div class="col">
        <button class="w-100" color="accent" mat-raised-button [routerLink]="[edPath+'/results/'+survey.id]">Analyze
          Results
        </button>
      </div>
      <div class="col" *ngIf="isEDOwner">
        <button class="w-100" color="secondary" mat-raised-button (click)="compileSurvey()">Compile results
        </button>
      </div>
    </div>

    <app-survey-responses-table [survey]="survey" (onSurveyResponses)="onSurveyResponses($event)"></app-survey-responses-table>
  </mat-card-content>
</mat-card>
