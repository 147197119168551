import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageComponent} from "../../../../component/message";
import {Survey} from "../../../../model/common";
import {EdService} from "../../../../core/ed.service";
import {catchError, map} from "rxjs/operators";
import {AuthApiService} from "../../../../core/auth-api.service";
import {DialogService} from "../../../../component/dialog";
import {Observable, of, throwError} from "rxjs";

@Component({
  selector: 'app-eddashboard',
  templateUrl: './eddashboard.component.html',
  styleUrls: ['./eddashboard.component.scss']
})
export class EDDashboardComponent implements OnInit {
  EDPath = this.auth.getEDPath();

  @ViewChild('message') message: MessageComponent;

  dashboardDS;
  dashboardData;
  surveys: Survey[];
  stats = [];

  isEDOwner: boolean = false;

  constructor(
    private edService: EdService,
    private auth: AuthApiService,
    private dialog: DialogService
  ) { }

  ngOnInit() {
    this.isEDOwner = this.auth.isEDOwner();
    this.dashboardDS = this.edService.dashboard().pipe(map(r => {
      this.dashboardData = r;

      if (this.dashboardData['current_surveys']) {
        this.surveys = <Survey[]>this.dashboardData['current_surveys'];
      }

      this.updateStats();
      return r;
    }), catchError((err, caught: Observable<any>) => {
      this.message.renderApiError(err);
      return of([]);
    }));

    if (this.auth.user.ed) {
      this.dashboardDS.subscribe();
    }
  }

  updateStats() {
    this.stats = [
      {
        theme: 'primary',
        title: 'Registered Users',
        value: this.dashboardData['registered_users'],
        iconName: 'person_pin'
      },
      {theme: 'secondary', title: 'Survey Takers', value: this.dashboardData['survey_takers'], iconName: 'people'},
      {theme: 'accent', title: 'Paid User', value: 0, iconName: 'attach_money'},
      {theme: 'warn', title: 'Active Users', value: this.dashboardData['active_users'], iconName: 'playlist_add_check'},
    ];
  }

  onSurveyChanged() {
    this.dashboardDS.subscribe();
  }

}
