import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";
import {Survey, SurveyDefinition, User} from "../../../../model/common";
import {SurveyService} from "../../../../core/survey.service";
import {MessageComponent} from "../../../../component/message";
import {DialogService} from "../../../../component/dialog";
import {EditSurveyDetailsComponent} from "../modals/edit-survey-details/edit-survey-details.component";
import {InviteSurveyUsersModalComponent} from "../modals/invite-survey-users-modal/invite-survey-users-modal.component";
import {SurveyEditorComponent} from "../../../../components/survey-editor/survey-editor.component";
import {catchError, map} from "rxjs/operators";
import {NotificationService} from "../../../../component/notification";
import {Observable, of, Subscription} from "rxjs";
import {NavigationExtras, Router} from "@angular/router";
import {AuthApiService} from "../../../../core/auth-api.service";
import {ViewSurveyComponent} from "../../survey/view-survey/view-survey.component";
import {UtilityService} from "../../../../components/utility.service";
import {EdService} from "../../../../core/ed.service";

@Component({
  selector: 'app-list-surveys',
  templateUrl: './list-surveys.component.html',
  styleUrls: ['./list-surveys.component.scss']
})
export class ListSurveysComponent implements OnInit, OnDestroy {

  @ViewChild('message') message: MessageComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  subsCan = new Subscription();
  displayedColumns: string[] = ['title', 'period', 'users_count', 'status', 'actions'];
  dataSource = new MatTableDataSource<User>();
  edPath = this.auth.getEDPath();

  isEDOwner: boolean = false;
  isEDMember: boolean = false;
  isEDSurveyManager: boolean = false;

  constructor(
    private surveyService: SurveyService,
    private dialog: DialogService,
    private notification: NotificationService,
    private auth: AuthApiService,
    private router: Router,
    private util: UtilityService,
    private edService: EdService
  ) {
  }

  data = this.surveyService.all().pipe(map(r => {
    this.dataSource.data = r['data'];
    return r;
  }), catchError((err, caught: Observable<any>) => {
    this.message.renderApiError(err);
    return of([]);
  }));

  ngOnInit() {
    this.isEDOwner = this.auth.isEDOwner();
    this.isEDMember = this.auth.isEDMember();
    this.isEDSurveyManager = this.auth.isEDSurveyManager();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.data.subscribe();
  }

  ngOnDestroy(): void {
    if (this.subsCan) {
      this.subsCan.unsubscribe();
    }
  }

  edit(survey) {
    const dialogRef = this.dialog.open(EditSurveyDetailsComponent, {
      disableClose: true,
      panelClass: 'dialog-md'
    });

    dialogRef.componentInstance.survey = survey;
    dialogRef.componentInstance.onSaved.subscribe(() => {
      this.data.subscribe();
    });

  }

  inviteUsers(survey) {
    const dialogRef = this.dialog.open(InviteSurveyUsersModalComponent, {
      disableClose: true,
      width: '850px',
      maxHeight: '90vh'
    });

    dialogRef.componentInstance.survey = survey;
  }

  editDefinition(survey: Survey) {
    this.edService.getDefinition(survey.survey_definition_id).subscribe(r => {
        const dialogRef = this.dialog.open(SurveyEditorComponent, {
          maxWidth: '70vw',
          maxHeight: '90vh',
          minHeight: '600px',
          minWidth: '750px',
          disableClose: true
        });

        dialogRef.componentInstance.surveyDefinition = r;
        dialogRef.componentInstance.survey = survey;

        this.subsCan.add(dialogRef.componentInstance.saveClicked.subscribe((surveyDefinition: SurveyDefinition) => {
          this.surveyService.updateDefinition(survey.id, surveyDefinition).subscribe(r => {
            this.notification.success('Success.', 'Saved', {duration: 1000});
            this.data.subscribe();
            dialogRef.close();
          }, e => {
            this.notification.error('Save failed.', e.error['message'], {duration: 0});
          });
        }));

        this.subsCan.add(dialogRef.componentInstance.closeClicked.subscribe(() => {
          dialogRef.close();
        }));
      },
      e => {
        this.message.renderApiError(e);
      }
    );
  }

  delete(survey: Survey) {
    this.dialog.confirm({
      title: 'Delete survey',
      message: 'Are you sure?',
      cancelButton: 'Cancel',
      acceptButton: 'Delete'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.delSurvey(survey.id).subscribe(r => {
        this.message.renderSuccess();
        this.data.subscribe();
      }, e => this.message.renderApiError(e));
    }));

  }

  previewSurvey(survey: Survey) {
    this.util.previewSurvey(survey.id);
  }

  viewSurvey(survey: Survey) {
    const dialogRef = this.dialog.open(ViewSurveyComponent, {
      panelClass: 'dialog-lg',
      disableClose: false
    });
    dialogRef.componentInstance.isModal = true;
    dialogRef.componentInstance.survey = survey;
    this.subsCan.add(dialogRef.componentInstance.onClose.subscribe(() => dialogRef.close()));

    this.subsCan.add(dialogRef.componentInstance.onChange.subscribe(() => {
      this.data.pipe(map((r: any) => {
        let data = <Survey[]>r['data'];
        dialogRef.componentInstance.survey = data.find(survey => survey.id == dialogRef.componentInstance.survey.id);
      })).subscribe();
    }));
  }

  duplicate(survey: Survey) {
    let url = this.auth.getEDPath() + `/surveys/start`;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "duplicateSurveyID": survey.id,
      }
    };

    this.router.navigate([url], navigationExtras);
  }

  pause(survey: Survey) {
    this.dialog.confirm({
      title: 'Pause survey',
      message: 'Are you sure?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, Pause'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.pause(survey.id).subscribe(r => {
        this.message.renderSuccess();
        this.data.subscribe();
      }, e => this.message.renderApiError(e));
    }));
  }

  unpause(survey: Survey) {
    this.dialog.confirm({
      title: 'Start survey',
      message: 'Are you sure?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, Start'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.unpause(survey.id).subscribe(r => {
        this.message.renderSuccess();
        this.data.subscribe();
      }, e => this.message.renderApiError(e));
    }));
  }

  cancel(survey: Survey) {
    this.dialog.confirm({
      title: 'Cancel survey',
      message: 'Are you sure?',
      cancelButton: 'No',
      acceptButton: 'Yes, Cancel the survey'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.cancel(survey.id).subscribe(r => {
        this.message.renderSuccess();
        this.data.subscribe();
      }, e => this.message.renderApiError(e));
    }));
  }

  canEdit(survey: Survey) {
    return survey.status == 'planning' || survey.status == 'paused';
  }
}
