<app-message
    #message
    [opened]="false"
>
  <button type="button" app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<h2 mat-dialog-title class="mb-5" *ngIf="isModal">
  Survey results
  <button mat-icon-button type="button" color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<div class="dashboard-header" *ngIf="survey">
  <div class="d-flex justify-content-between">
    <a mat-fab [color]="'primary'" class="mr-1" title="Back"
        [routerLink]="[EDPath+'/results']">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </a>
    <div class="">
      <h1 class="dashboard-header__title">{{survey.title}}</h1>
      <p class="dashboard-header__desc" [innerHTML]="survey.description"></p>
      <br>
      <div class="" *ngIf="showToggles">
        <mat-button-toggle-group appearance="legacy"
          #submittedResponsesToggle="matButtonToggleGroup"
          [(ngModel)]="includeNotSubmittedResponses" 
          (change)="onSubmittedResponsesToggle()">
          <mat-button-toggle [value]="true" aria-label="Include unsubmitted responses">
            Include
          </mat-button-toggle>
          <mat-button-toggle [value]="false" aria-label="Exclude unsubmitted responses">
            Exclude
          </mat-button-toggle>
        </mat-button-toggle-group>
        &nbsp;not submitted responses in results    
      </div>

      <br>
      <div class="" *ngIf="showToggles">
        <mat-button-toggle-group appearance="legacy"
          #resultsUnderSafeHarborToggle="matButtonToggleGroup"
          [(ngModel)]="showResultsBelowSafeHarbor">
          <mat-button-toggle [value]="1" aria-label="Show results below safe harbor">
            Show
          </mat-button-toggle>
          <mat-button-toggle [value]="2" aria-label="Mention results below safe harbor">
            Mention
          </mat-button-toggle>
          <mat-button-toggle [value]="3" aria-label="Hide results below safe harbor">
            Hide
          </mat-button-toggle>
        </mat-button-toggle-group>
        &nbsp;results below the safe harbor limit    
      </div>

    </div>
    <div>
      <a *ngIf="survey.infographic_pdf_url" mat-fab color="primary" class="" title="Infographic PDF" target="_blank"
        href="{{survey.infographic_pdf_url}}">
        <mat-icon>perm_device_information</mat-icon>
      </a>
      &nbsp;
      <a *ngIf="survey.results_pdf_url" mat-fab color="primary" class="" title="Survey results PDF" target="_blank"
      href="{{survey.results_pdf_url}}">
        <mat-icon>picture_as_pdf</mat-icon>
      </a>
      &nbsp;
      <a mat-fab color="accent" class="" title="Print" target="_blank"
        [routerLink]="[surveyService.getSurveyPrintPath(survey)]"
        [queryParams]="{unsubmitted: includeNotSubmittedResponses, 'safe-harbor': showResultsBelowSafeHarbor}"
        (click)="onSurveyPrintClicked()"
      >
        <mat-icon>print</mat-icon>
      </a>
    </div>
  </div>
</div>

<div class="mat-typography">
  <ng-container *ngIf="surveyResults">
      <app-survey-results-filters
        [surveyResults]="surveyResults"
        [filters]="filters"
        (onFilter)="onFilter($event)"
      >
      </app-survey-results-filters>


      <mat-tab-group mat-align-tabs="center" [dynamicHeight]="true">
        <mat-tab label="Benefits survey">
          <ng-container [ngTemplateOutlet]="baseResults"></ng-container>
        </mat-tab>
        <mat-tab label="Compensation survey" class="pt-1" *ngIf="compensationSectionResults">
          <ng-template matTabContent>
            <ng-container [ngTemplateOutlet]="compensationResults"></ng-container>
          </ng-template>
        </mat-tab>

        <mat-tab label="Businesses" class="pt-1" *ngIf="surveyResults.businesses">
          <ng-template matTabContent>
            <ng-container [ngTemplateOutlet]="businessesList"></ng-container>
          </ng-template>
        </mat-tab>

      </mat-tab-group>

  </ng-container>
</div>


<ng-template #baseResults>
  <div class="row no-gutters mt-2">

    <div class="col-md-3">
      <app-survey-results-table-of-contents
        [surveyResults]="surveyResults"
        (sectionSelected)="activeSection = $event"
      ></app-survey-results-table-of-contents>
    </div>

    <div class="col-md-9">
      <app-survey-results-section
        [survey]="survey"
        [sectionResult]="activeSection"
        [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
      ></app-survey-results-section>
    </div>

  </div>
</ng-template>


<ng-template #compensationResults>
  <div class="row no-gutters mt-2">
    <app-survey-results-compensation
      [survey]="survey"
      [results]="compensationSectionResults"
      [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
    >
    </app-survey-results-compensation>
  </div>
</ng-template>


<ng-template #businessesList>
  <div class="row no-gutters mt-2">

    <mat-card class="w-100">
      <mat-card-header>
        <mat-card-title>
          Businesses
        </mat-card-title>
        <mat-card-subtitle>
          Business participated in the survey
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="mat-typography">
        <ul style="columns: 2; column-gap: 2em;">
          <li
            *ngFor="let business of surveyResults.businesses"
          >{{business?.name}}</li>
        </ul>
      </mat-card-content>
    </mat-card>

  </div>
</ng-template>
