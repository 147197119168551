import { Component, OnInit, ViewChild } from '@angular/core';
import {MessageComponent} from "../../../../component/message";
import {Survey} from "../../../../model/common";
import {EdService} from "../../../../core/ed.service";
import {catchError, map} from "rxjs/operators";
import {AuthApiService} from "../../../../core/auth-api.service";
import {DialogService} from "../../../../component/dialog";
import {Observable, of } from "rxjs";

@Component({
  selector: 'app-viewer-dashboard',
  templateUrl: './viewer-dashboard.component.html',
  styleUrls: ['./viewer-dashboard.component.scss']
})
export class ViewerDashboardComponent implements OnInit {

  @ViewChild('message') message: MessageComponent;

  dashboardDS;
  surveys: Survey[];

  constructor(
    private edService: EdService,
    private auth: AuthApiService,
    private dialog: DialogService,
  ) { }

  ngOnInit() {
    this.dashboardDS = this.edService.dashboard().pipe(map(r => {
      const dashboardData = r;

      if (dashboardData['current_surveys']) {
        this.surveys = <Survey[]>dashboardData['current_surveys'];
      }
      return r;
    }), catchError((err, caught: Observable<any>) => {
      this.message.renderApiError(err);
      return of([]);
    }));

    if (this.auth.user.ed) {
      this.dashboardDS.subscribe();
    }
  }

}
