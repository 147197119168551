<app-message
    #message
    [opened]="false"
    class="m-2"
></app-message>

<div class="text-right">
  <mat-form-field class="w-50 mt-1 mb-1">
    <input #occupationFilter matInput placeholder="Filter by occupation">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<mat-accordion class="occupations-list">
  <ng-container *ngFor="let industry of results.industries">
    <mat-expansion-panel #industryPanel *ngIf="(industry.occupations | filterQuestions | filterTitle:occupationFilter.value).length">
      <mat-expansion-panel-header>
        <mat-panel-title class="font-weight-bold">
          {{industry.title}}
        </mat-panel-title>
        <mat-panel-description>
          {{(industry.occupations | filterQuestions | filterTitle:occupationFilter.value).length}} {{occupationFilter.value.length ? 'matching' : ''}}
          occupations
        </mat-panel-description>
      </mat-expansion-panel-header>


      <ng-template matExpansionPanelContent>

        <mat-accordion class="occupations-list">

          <ng-container *ngFor="let occupation of industry.occupations | filterTitle:occupationFilter.value">

            <mat-expansion-panel #panel *ngIf="occupation['questions']">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{occupation.title}}
                </mat-panel-title>

                <mat-panel-description>
                  <ng-container *ngIf="occupation['questions']; else ResultsPanelDescriptionEmpty">
                  </ng-container>
                </mat-panel-description>

                <ng-template #ResultsPanelDescriptionEmpty>
                  No data
                </ng-template>

              </mat-expansion-panel-header>

              <ng-template matExpansionPanelContent>
                <div class="mt-3">
                  <app-survey-results-occupation-result
                    [survey]="survey"
                    [occupation]="occupation"
                    [surveyResultsSection]="results"
                    [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
                  ></app-survey-results-occupation-result>
                </div>

              </ng-template>


            </mat-expansion-panel>

          </ng-container>
        </mat-accordion>

      </ng-template>


    </mat-expansion-panel>

  </ng-container>
</mat-accordion>
