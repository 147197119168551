import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MessageComponent} from 'src/app/component';
import {IndustriesService} from 'src/app/core/industries.service';
import {Occupation, Survey, SurveyResultsSection} from "../../../../../../model/common";
import {BlockTripettoNumberResultComponent} from "../../result-blocks/block-tripetto-number-result/block-tripetto-number-result.component";
@Component({
  selector: 'app-survey-results-compensation',
  templateUrl: './survey-results-compensation.component.html',
  styleUrls: ['./survey-results-compensation.component.scss']
})
export class SurveyResultsCompensationComponent implements OnInit {

  @ViewChild('message') message: MessageComponent;

  @Input('survey') survey: Survey;
  @Input('results') results: SurveyResultsSection;
  @Input('showResultsBelowSafeHarbor') showResultsBelowSafeHarbor: number;

  isDrama2022 = false;
  occupationQuestions = [];

  groupNumberQuestions = [
    'min_range_base_salary_fulltime',
    'max_range_base_salary_fulltime',
    'max_hire_base_salary_fulltime',
    'min_actual_base_salary_fulltime',
    'max_actual_base_salary_fulltime',
    'base_salary_fulltime',
    'other_cash_compensation',
    'min_range_rate',
    'max_range_rate',
    'min_actual_rate',
    'base_rate',
    'max_actual_rate',
    'step_progression_num_steps',
    'other_cash_compensation_hourly',
  ];

  constructor(
    private industriesService: IndustriesService,
  ) { }

  ngOnInit() {
    // this.isDrama2022 = this.isSurveyDescription("This is the DRMA Wage & Benefit Survey for 2022.");
    this.isDrama2022 = this.needDramaChanges();

    this.occupationQuestions = Object.values(this.results.questions);
    this.hackQuestionTextForDRMA();

    for (let industry of this.results.industries) {
      for (let occupation of industry.occupations) {
        if (!occupation.description) {
          this.initOccupationDescription(occupation);
        }
          const includeNCount = true;
          const options = {style: 'currency', currency: 'USD'};
          const includeTotal = false;
          let groupQuestions = [];
          let questionArray = [];

          for (let alias in occupation['questions']) {
            let occupationQuestionResult = occupation['questions'][alias];
            occupationQuestionResult['alias'] = alias;
            questionArray.push(occupationQuestionResult);
            let occupationQuestion = this.occupationQuestions.find(q => q['alias'] == alias);
            if (occupationQuestion) {
              occupationQuestionResult['block'] = occupationQuestion['block'];
              occupationQuestionResult['name'] = occupationQuestion['name'];
            }
            if (this.groupNumberQuestions.includes(alias)) {
              occupationQuestionResult['stats'] =
                BlockTripettoNumberResultComponent.calcStats(occupationQuestionResult['results']['answers'], includeNCount, includeTotal, options);
              groupQuestions.push(occupationQuestionResult);
            }
          }
          occupation['groupQuestions'] = groupQuestions;
          occupation['questionArray'] = questionArray;
      }
    }
  }

  needDramaChanges(){
    let d = new Date(this.survey.end_date);
    return d.getFullYear() >= 2022 && this.survey.ed_id == 19;
  }

  getKeys(object) {
    return Object.keys(object);
  }

  initOccupationDescription(occupation: Occupation) {
    this.industriesService.getOccupation(occupation.id).subscribe(r => {
      occupation.description = r['description'];
    }, e => {
      this.message.renderApiError(e);
    });
  }

  isSurveyDescription(surveyDescription: string): boolean {
    return this.survey.description == surveyDescription;
  }

  hackQuestionTextForDRMA() {
    // this is a custom hack for DRMA to manually change the word "incumbents" to "employees"
    // if (!this.isSurveyDescription("This is the DRMA Wage & Benefit Survey for 2021.") &&
    //     !this.isSurveyDescription("This is the DRMA Wage & Benefit Survey for 2022.")) 
    if(!this.isSurveyDescription("This is the DRMA Wage & Benefit Survey for 2021.") && !this.needDramaChanges()){
      return;
    }
    for (let question of this.occupationQuestions) {
      if (question['alias'] === 'incumbents_count') {
        question['name'] = question['name'].replace('incumbents', 'employees');
      }
    }
  }

}
