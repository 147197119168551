import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {SurveyService} from "../../../../../core/survey.service";
import {Survey, User} from "../../../../../model/common";
import {catchError, finalize, map} from "rxjs/operators";
import {MessageComponent} from "../../../../../component/message";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-invite-survey-users-modal',
  templateUrl: './invite-survey-users-modal.component.html',
  styleUrls: ['./invite-survey-users-modal.component.scss']
})
export class InviteSurveyUsersModalComponent implements OnInit {

  @ViewChild('message') messageBox: MessageComponent;

  @Input() survey: Survey;
  @Output() onSaved = new EventEmitter();

  surveyUsers: Observable<any>;

  selectedUsers: User[];


  constructor(
    public dialogRef: MatDialogRef<InviteSurveyUsersModalComponent>,
    private surveyService: SurveyService
  ) { }

  ngOnInit() {

    this.surveyUsers = this.surveyService.getUsers(this.survey.id).pipe(
      map(r => {
        return r;
      }), catchError((err, caught: Observable<any>) => {
        this.messageBox.renderApiError(err);
        return of([]);
      })
    );
  }

  onUsersSelected(users) {
    this.selectedUsers = users;
  }

  save(btn) {
    btn.disabled = true;
    this.messageBox.renderApiError();
    this.surveyService.inviteUsers(this.survey.id, this.selectedUsers)
      .pipe(finalize(() => {
        btn.disabled = false;
      }))
      .subscribe(
        (r) => {
          this.messageBox.renderSuccess(r);
          this.onSaved.emit();
        },
        e => this.messageBox.renderApiError(e)
      );
  }

  cancel() {
    this.dialogRef.close();
  }
}
