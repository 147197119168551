import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialogRef, MatTableDataSource} from '@angular/material';
import {User} from '../../../../../../model/common';
import {DialogService} from '../../../../../../component/dialog';
import {NewUserDialogComponent} from '../new-user-dialog/new-user-dialog.component';

@Component({
    selector: 'app-add-successor-dialog',
    templateUrl: './add-successor-dialog.component.html',
    styleUrls: ['./add-successor-dialog.component.scss']
})
export class AddSuccessorDialogComponent implements OnInit {

    @Input() businessUsers: Array<User> = [];
    @Input() predecessorUser: User;

    @Output() onComplete = new EventEmitter<User>();

    selectedUser: User;
    selectedUserHasResponse: boolean = false;

    displayedColumns: string[] = ['select', 'first_name', 'last_name', 'email', 'business', 'has_response'];
    dataSource = new MatTableDataSource<User>();

    constructor(
      public dialogRef: MatDialogRef<AddSuccessorDialogComponent>,
      private dialog: DialogService
    ) { }

    ngOnInit() {
      this.dataSource.data = this.businessUsers.filter(user => user.id != this.predecessorUser.id);
    }

    cancelClick() {
        this.dialogRef.close();
    }

    onSelectUserClicked(element) {
      this.selectedUser = element;
      this.selectedUserHasResponse = element['has_response'];
    }

    newUserClick() {
        const d = this.dialog.open(NewUserDialogComponent, {
            disableClose: true,
            panelClass: 'dialog-md'
        });

        const component = d.componentInstance;
        component.onComplete.subscribe(user => {
            this.dataSource.data = [...[user], ...this.dataSource.data];
            this.onSelectUserClicked(user);
            d.close();
        });
    }

  onAddSuccessorClicked() {
    this.onComplete.emit(this.selectedUser);
  }

}
