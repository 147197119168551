import {Component, Input, OnInit} from '@angular/core';
import {Survey, SurveyResultsSection, SurveySectionResponse} from "../../../../../model/common";
import {AppSettings} from 'src/app/core/app.settings';

@Component({
  selector: 'app-survey-results-section',
  templateUrl: './survey-results-section.component.html',
  styleUrls: ['./survey-results-section.component.scss']
})
export class SurveyResultsSectionComponent implements OnInit {

  @Input('survey') survey: Survey;
  @Input('sectionResult') sectionResult: SurveyResultsSection;
  @Input('sectionNumber') sectionNumber: Number;
  @Input('showResultsBelowSafeHarbor') showResultsBelowSafeHarbor: number = AppSettings.ShowResultsBelowSafeHarbor.mention;

  constructor() { }

  ngOnInit() {
    this.sectionNumber = this.sectionNumber || 0;
  }

  sectionHasQuestions() {
    if (this.showResultsBelowSafeHarbor === AppSettings.ShowResultsBelowSafeHarbor.hide) {
      let questionFound = false;
      for (let question of this.sectionResult.questions) {
        if (!this.isLowAccuracyQuestion(question)) {
          questionFound = true;
          break;
        }
      }
      return questionFound;
    } else {
      return true;
    }
  }

  getQuestionsToShow() {
    if (this.showResultsBelowSafeHarbor !== AppSettings.ShowResultsBelowSafeHarbor.hide) {
      return this.sectionResult.questions;
    } else {
      let questions = [];
      for (let question of this.sectionResult.questions) {
        if (!this.isLowAccuracyQuestion(question)) {
          questions.push(question);
        }
      }
      return questions;
    }
  }

  isLowAccuracyQuestion(question: any) {
    if (!question.results) {
      return true;
    }
    return question.results['answered_count'] < this.survey.safe_harbor_count;
  }

}
