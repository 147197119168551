import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import {Subscription} from 'rxjs';
import {Survey, SurveyDefinition} from '../../../../../model/common';
import {MessageComponent} from '../../../../../component/message';
import {DialogService} from '../../../../../component/dialog';
import {AuthApiService} from '../../../../../core/auth-api.service';
import {UtilityService} from '../../../../../components/utility.service';
import {SurveyResultsComponent} from '../../../survey/survey-results/survey-results.component';
@Component({
  selector: 'app-viewer-dashboard-survey',
  templateUrl: './viewer-dashboard-survey.component.html',
  styleUrls: ['./viewer-dashboard-survey.component.scss']
})
export class ViewerDashboardSurveyComponent implements OnInit, OnDestroy {

  @Input() survey: Survey;
  @ViewChild('message') message: MessageComponent;

  edPath = this.auth.getEDPath();
  subsCan = new Subscription();

  constructor(
    private dialog: DialogService,
    private auth: AuthApiService,
    private util: UtilityService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subsCan.unsubscribe();
  }

  viewResults() {
    const dialogRef = this.dialog.open(SurveyResultsComponent, {
      disableClose: false,
      panelClass: 'dialog-lg',
    });

    dialogRef.componentInstance.isModal = true;
    this.subsCan.add(dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.init(this.survey.id);
    }));

    this.subsCan.add(dialogRef.componentInstance.onClose.subscribe(() => {
      dialogRef.close();
    }));
  }

  viewSurvey() {
    this.util.previewSurvey(this.survey.id);
  }

}
