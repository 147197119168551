import {Component, Input, OnInit, AfterViewInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute } from "@angular/router";
import {SurveyService} from "../../../../../core/survey.service";
import {AuthApiService} from "../../../../../core/auth-api.service";
import {ConfigService} from "../../../../../core/config.service";
import {ED, Survey, SurveyResults, SurveyResultsSection} from "../../../../../model/common";
import {catchError, finalize, map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {MessageComponent} from "../../../../../component/message";
import {UserSettingsService} from '../../../../../core/user.settings.service';
import {AppSettings} from 'src/app/core/app.settings';

@Component({
  selector: 'app-survey-print-results',
  templateUrl: './survey-print-results.component.html',
  styleUrls: ['./survey-print-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyPrintResultsComponent implements OnInit, AfterViewInit {

  @Input() survey: Survey;
  @Input() surveyResults: SurveyResults;

  @ViewChild('message') message: MessageComponent;

  EDPath = this.auth.getEDPath();
  ED: ED;
  isLoading = false;

  sID; 

  private includeNotSubmittedResponses = false;
  private showResultsBelowSafeHarbor: number = AppSettings.ShowResultsBelowSafeHarbor.mention;
  private filterState = null;
  public filteredCompensationData = null;

  public showTableOfContents = {
    showSectionIndexes: [],
    sections: [
      { showQuestionIndexes: [] }
    ]
  };

  constructor(
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    public surveyService: SurveyService,
    private auth: AuthApiService,
    private configService: ConfigService,
    private userSettingsService: UserSettingsService,
  ) { }

  ngOnInit() {
    this.ED = this.auth.user.ed;

    setTimeout(() => {
      this.configService.setSettings({printMode: true});
    }, 0);

    this.initFilters();
  }

  ngAfterViewInit() {
    this.includeNotSubmittedResponses = this.route.snapshot.queryParams['unsubmitted'] === 'true' ? true : false;
    this.includeNotSubmittedResponses = this.includeNotSubmittedResponses && this.auth.isEDMember();

    this.showResultsBelowSafeHarbor = parseInt(this.route.snapshot.queryParams['safe-harbor']) ?
      parseInt(this.route.snapshot.queryParams['safe-harbor']) : AppSettings.ShowResultsBelowSafeHarbor.mention;
    if (!this.auth.isEDMember() && this.showResultsBelowSafeHarbor === AppSettings.ShowResultsBelowSafeHarbor.show) {
      this.showResultsBelowSafeHarbor = AppSettings.ShowResultsBelowSafeHarbor.mention;
    }

    const surveyId = this.route.snapshot.paramMap.get('survey_id');
    this.sID = surveyId;
    
    if (surveyId) {
      this.init(surveyId);
    }

    this.changeDetector.detach();
  }

  initFilters() {
    this.filterState = this.userSettingsService.getUserSetting('filters', 'Array');
  }

  public init(surveyID) {
    this.message.renderApiError();
    this.isLoading = true;

    const payload = {
      filters: this.filterState,
      includeNotSubmittedResponses: this.includeNotSubmittedResponses,
    };

    this.surveyService.getSurveyResults(surveyID, payload).pipe(finalize(() => {
      this.isLoading = false;
    })).pipe(map(
      r => {
        this.survey = <Survey>r['survey'];
        this.surveyResults = <SurveyResults>r['results'];
        this.filterTableOfContents();
        this.filterCompensationData();
        setTimeout(() => this.changeDetector.detectChanges(), 0);
      }), catchError((err, caught: Observable<any>) => {
        return of([]);
    })).subscribe();
  }

  filterTableOfContents() {
    for (let sectionIndex = 0; sectionIndex < this.surveyResults.sections.length; sectionIndex++) {
      const section = this.surveyResults.sections[sectionIndex];
      this.showTableOfContents.sections.push({
        showQuestionIndexes: []
      });
      if (!section.is_compensation_survey) {
        let questionFound = false;
        for (let questionIndex = 0; questionIndex < section.questions.length; questionIndex++) {
          const question = section.questions[questionIndex];
          if (this.showQuestion(question)) {
            this.showTableOfContents.sections[sectionIndex].showQuestionIndexes.push(questionIndex);
            questionFound = true;
          }
        }
        if (questionFound) {
          this.showTableOfContents.showSectionIndexes.push(sectionIndex);
        }
      } else {
        this.showTableOfContents.showSectionIndexes.push(sectionIndex);
        this.showTableOfContents['compensationSurvey'] = {
          showIndustryIndexes: [],
          showIndustryOccupations: {}
        };

        for (let industryIndex = 0; industryIndex < section.industries.length; industryIndex++) {
          let industry = section.industries[industryIndex];
          for (let occupationIndex = 0; occupationIndex < industry.occupations.length; occupationIndex++) {
            let occupation = industry.occupations[occupationIndex];
            if (this.showAnyQuestion(occupation)) {
              if (!this.showTableOfContents['compensationSurvey'].showIndustryOccupations.hasOwnProperty(industryIndex)) {
                this.showTableOfContents['compensationSurvey'].showIndustryIndexes.push(industryIndex);
                this.showTableOfContents['compensationSurvey'].showIndustryOccupations[industryIndex] = [];
              }
              this.showTableOfContents['compensationSurvey'].showIndustryOccupations[industryIndex].push(occupationIndex);
            }
          }
        }
      }
    }
  }

  filterCompensationData() {
    this.filteredCompensationData = {
      is_compensation_survey: true,
      name: "Compensation survey",
      industries: [],
      questions: [],
      sectionIndex: null
    };
    for (let sectionIndex of this.showTableOfContents.showSectionIndexes) {
      const section = this.surveyResults.sections[sectionIndex];
      if (section.is_compensation_survey) {

        this.filteredCompensationData.questions = section.questions;
        this.filteredCompensationData.sectionIndex = section['sectionIndex'];

        for (let industryIndex of this.showTableOfContents['compensationSurvey'].showIndustryIndexes) {

          let industry = {
            id: section.industries[industryIndex].id,
            title: section.industries[industryIndex].title,
            occupations: [],
          };

          for (let occupationIndex of this.showTableOfContents['compensationSurvey'].showIndustryOccupations[industryIndex]) {
            let occupation = section.industries[industryIndex].occupations[occupationIndex];
            industry.occupations.push(occupation);
          }
          this.filteredCompensationData.industries.push(industry);
        }

      }
    }
  }

  sanitizeHref(key: string) {
    return key.replace(/[ /()-]/g, '_');
  }

  scrollToElement(elementRef, event) {
    const element = document.getElementById(elementRef);
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
    event.preventDefault();
    event.stopPropagation();
  }

  isLowAccuracyQuestion(question) {
    if (!question.results) {
      return true;
    }
    return question.results['answered_count'] < this.survey.safe_harbor_count;
  }

  showQuestion(question) {
    return !this.isLowAccuracyQuestion(question) ||
      (this.showResultsBelowSafeHarbor === AppSettings.ShowResultsBelowSafeHarbor.show) ||
      (this.showResultsBelowSafeHarbor === AppSettings.ShowResultsBelowSafeHarbor.mention);
  }

  showAnyQuestion(occupation) {
    if (!occupation.hasOwnProperty('questions')) {
      return false;
    }
    for (let questionKey in occupation['questions']) {
      if (occupation['questions'].hasOwnProperty(questionKey)) {
        let question = occupation['questions'][questionKey];
        if (this.showQuestion(question)) {
          return true;
        }
      }

    }
    return false;
  }

}
