import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SurveyDefinition, SurveySection, Survey} from '../../model/common';
import {DialogService} from '../../component/dialog';
import {SurveyDefinitionEditorComponent} from './survey-definition-editor/survey-definition-editor.component';
import {Subscription} from 'rxjs';
import {IDefinition} from 'tripetto';
import {TakeSurveyComponent} from '../../pages/ed/survey/take-survey/take-survey.component';
import {take} from "rxjs/operators";
import {MessageComponent} from "../../component/message";
import {FormControl} from "@angular/forms";
import {NotificationService} from "../../component/notification/index";

export interface Question {
  value?: string;
  viewValue?: string;
}

export interface QuestionGroup {
  name?: string;
  questions?: Question[];
}

@Component({
  selector: 'app-survey-editor',
  templateUrl: './survey-editor.component.html',
  styleUrls: ['./survey-editor.component.scss'],
})
export class SurveyEditorComponent implements OnInit, OnDestroy {

  @ViewChild('message') public messageBox: MessageComponent;

  @Output() saveClicked = new EventEmitter<SurveyDefinition>();
  @Output() closeClicked = new EventEmitter();

  surveyDefinition: SurveyDefinition;
  survey: Survey;
  subs = new Subscription();
  @ViewChild('sectionsList') sectionsList: ElementRef;

  formFilterFieldsControl = new FormControl();
  questionGroups: QuestionGroup[] = [];
  excludedQuestionsGroups: QuestionGroup[] = [];
  excludedQuestionsFieldsControl = new FormControl();

  private excludedQuestionTypes = {
    'tripetto-block-dropdown': 'tripetto-block-dropdown',
    'tripetto-block-number': 'tripetto-block-number',
    'tripetto-block-text': 'tripetto-block-text',
    'tripetto-block-textarea': 'tripetto-block-textarea',
    'tripetto-block-radiobuttons': 'tripetto-block-radiobuttons',
    'tripetto-block-url': 'tripetto-block-url',
    'block-matrix-checkboxes': 'block-matrix-checkboxes',
    'block-matrix-radiobuttons': 'block-matrix-radiobuttons',
    'block-matrix-dropdowns': 'block-matrix-dropdowns',
    'block-checkboxes': 'block-checkboxes',
  };

  private quickFilterQuestionTypes = {
    'tripetto-block-dropdown': 'tripetto-block-dropdown',
    'tripetto-block-radiobuttons': 'tripetto-block-radiobuttons',
    'block-checkboxes': 'block-checkboxes',
  };

  private currentFilterQuestionTypes = {};

  constructor(
    private dialog: DialogService,
    private notification: NotificationService,
  ) { }

  ngOnInit(): void {
    if (!this.surveyDefinition) {
      this.surveyDefinition = {} as SurveyDefinition;
    }
    if (!this.isArray(this.surveyDefinition.definition)) {
      this.surveyDefinition.definition = [];
    }
    this.updateFilters();
    this.updateExcludedQuestions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // noinspection JSMethodCanBeStatic
  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  }

  moveSection($event, currentIndex, newIndex) {
    $event.stopPropagation();

    if (!this.canEditDefinition()) {
      this.notification.error('Survey must have planning status.', 'You cannot edit the survey definition!')
      return;
    }

    this.array_move(this.surveyDefinition.definition, currentIndex, newIndex);
  }

  isArray(obj) {
    return Array.isArray(obj);
  }

  onCloseClicked() {
    this.closeClicked.emit();
  }

  onSaveClicked() {
    this.saveClicked.emit(this.surveyDefinition);
  }

  addSurveySection() {
    const sectionList = this.sectionsList.nativeElement;
    if (!this.isArray(this.surveyDefinition.definition)) {
      this.surveyDefinition.definition = [];
    }

    this.surveyDefinition.definition.push({} as SurveySection);
    setTimeout(() => sectionList.scrollTop = sectionList.scrollHeight, 300);
  }

  deleteSurveySection($event, index) {
    $event.stopPropagation();
    this.dialog.confirm({
      title: 'Delete Survey Section!',
      message: 'Are you sure you want to delete this section?',
      cancelButton: 'Cancel',
      acceptButton: 'Delete'
    }).afterClosed().subscribe((r => {
      if (!r) {
        return;
      }
      this.surveyDefinition.definition.splice(index, 1);
      this.updateFilters();
    }));
  }

  getQuestionsCount(definition: IDefinition) {
    if (!definition || !definition.clusters || !definition.clusters.length) {
      return 0;
    }

    let c = 0;

    definition.clusters.forEach(cluster => {
      if (!cluster.nodes) {
        return;
      }
      cluster.nodes.forEach(node => {
        if (!node.block) {
          return;
        }
        c++;
      });
    });

    return c;
  }

  canEditDefinition() {
    if (!this.survey) { return true; }
    return this.survey.status == 'planning'
      // remove this code after DRMA survey closes
      || (this.survey.status == 'paused' && this.survey.title == 'DRMA Wage & Benefit Survey 2021');
      // remove this code after DRMA survey closes
  }

  canEditExcludedQuestions() {
    if (!this.survey) { return true; }
    return this.survey.status !== 'closed';
  }

  editSurveyDefinition($event, section: SurveySection, idx: Number) {
    $event.stopPropagation();

    if (!this.canEditDefinition()) {
      this.notification.error('Survey must have planning status.', 'You cannot edit the survey definition!')
      return;
    }

    const dialogRef = this.dialog.open(SurveyDefinitionEditorComponent, {
      panelClass: ['dialog-fullscreen', 'dialog-editor'],
      hasBackdrop: false,
      disableClose: true
    });

    dialogRef.componentInstance.definition = section.definition;

    this.subs.add(dialogRef.componentInstance.saveClicked.subscribe(definition => {
      section.definition = {...definition};
      dialogRef.close();
      this.updateFilters();
    }));

    this.subs.add(dialogRef.componentInstance.closeClicked.subscribe(() => {
      dialogRef.close();
    }));

  }

  markAsCompensationSurvey($event, section: SurveySection, idx: Number) {
    $event.stopPropagation();
    section.is_compensation_survey = true;
  }

  unmarkCompensationSurvey($event, section: SurveySection, idx: Number) {
    $event.stopPropagation();
    section.is_compensation_survey = false;
  }

  previewSurvey() {
    const dialogRef = this.dialog.open(TakeSurveyComponent, {
      panelClass: 'dialog-lg',
      disableClose: true
    });

    dialogRef.componentInstance.isModal = true;
    dialogRef.componentInstance.readOnly = true;
    dialogRef.componentInstance.surveyDefinition = this.surveyDefinition;

    dialogRef.componentInstance.onClose.pipe(take(1)).subscribe(() => {
      dialogRef.close();
    });
  }

  updateFilters() {
    this.questionGroups = this.getQuestionsFiltered(this.surveyDefinition, this.quickFilterQuestionTypes);
    this.formFilterFieldsControl.setValue(this.surveyDefinition.filters);
  }

  updateExcludedQuestions() {
    this.excludedQuestionsGroups = this.getQuestionsFiltered(this.surveyDefinition, this.excludedQuestionTypes);
    this.excludedQuestionsFieldsControl.setValue(this.surveyDefinition.excluded_questions);
  }


  getQuestionsFiltered(surveyDefinition: SurveyDefinition, questionTypes): QuestionGroup[] {
    const questionGroups: QuestionGroup[] = [];
    this.currentFilterQuestionTypes = questionTypes;

    if (surveyDefinition.definition && surveyDefinition.definition.length) {
      surveyDefinition.definition.forEach(section => {

        const questionGroup = {
          name: section.definition.name,
          questions: []
        };

        this.addClusterQuestions(section.definition.clusters, questionGroup);

        if (questionGroup.questions.length) {
          questionGroups.push(questionGroup);
        }
      });
    }

    return questionGroups;
  }

  addClusterQuestions(clusters, questionGroup) {
    clusters.forEach(cluster => {
      if (cluster.hasOwnProperty('nodes') && Array.isArray(cluster.nodes)) {
        cluster.nodes.forEach(node => {
          if (node && node.block && this.currentFilterQuestionTypes.hasOwnProperty(node.block.type)) {
            questionGroup.questions.push({
              viewValue: node.name,
              value: node.id
            });
          }
        });
      }
      if (cluster.hasOwnProperty('branches') && this.isArray(cluster.branches)) {
        cluster.branches.forEach(branch => {
          this.addClusterQuestions(branch.clusters, questionGroup);
        });
      }
    });
  }


  filterSelectionChange($event) {
    this.surveyDefinition.filters = $event.value;
  }

  excludedQuestionsSelectionChange($event) {
    this.surveyDefinition.excluded_questions = $event.value;
  }

  isEditingExistingSurvey() {
    return !!this.surveyDefinition.survey_id;
  }
}
