<app-message
    #message
    [opened]="false"
    class="m-2"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<div class="row">
  <mat-card-title>Job description</mat-card-title>
  <mat-card-content>
    <div [innerHTML]="occupation.description"></div>
  </mat-card-content>
</div>

<ng-container *ngIf="isDrama2022">
  <ng-container *ngFor="let question of occupation.questionArray;index as i">
    <div class="row no-gutters" *ngIf="drama2022Questions.includes(question['alias']) && !hideSafeHarborQuestion(question)">
      <div class="col">
        <mat-card class="w-100">
          <mat-card-header>
            <mat-card-title [id]="question.id">
              {{sectionNumber ? sectionNumber + '.' : ''}}{{questionNumber ? questionNumber + '.' : ''}} {{question.name}}
            </mat-card-title>
            <mat-card-subtitle *ngIf="question.description">
              {{question.description}}
            </mat-card-subtitle>
            <mat-card-subtitle class="font-weight-bold" *ngIf="question.results">
              <span class="d-flex align-items-center ml-3" [ngClass]="{'text-danger':isLowAccuracyQuestion(question)}"
                    [matTooltip]="isLowAccuracyQuestion(question) ? 'Collected data is not sufficient to render an accurate result.' : null">
               Answered {{question.results['answered_count']}} times
              </span>
              <span class="d-flex ml-3">Skipped {{question.results['skipped_count']}} times</span>
              <span *ngIf="!failToSafeHarborQuestion(question)" class="ml-3">Total Number of Employees = {{getQuestionTotal(question)}}</span>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isDrama2022">
  <ng-container *ngFor="let question of occupation.questionArray;index as i">
    <div class="row no-gutters" *ngIf="regularQuestions.includes(question['alias']) && !hideSafeHarborQuestion(question)">
      <div class="col">
        <app-survey-results-question
          [survey]="survey"
          [question]="question"
          [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
        >
        </app-survey-results-question>
      </div>
    </div>
  </ng-container>
</ng-container>


<mat-card *ngIf="!hideSafeHarborSection()">
  <mat-card-title>Compensation data</mat-card-title>
  <mat-card-content>

    <div *ngIf="failToSafeHarborSection()">
      <div class="text-center">
        <h1 class="d-block">
          <i class="material-icons" style="font-size: 3em; color: #BDBDBD;">
            sentiment_dissatisfied
          </i></h1>
        <h3 class="text-dark">
          We are sorry but the collected data is not sufficient to render an accurate result.
        </h3>
      </div>
    </div>

    <table *ngIf="!failToSafeHarborSection()" class="mat-table">
      <thead>
      <tr *ngIf="occupation.groupQuestions.length > 0" class="mat-header-row">
        <th class="mat-header-cell text-right">N = Number of Employers</th>
        <th class="mat-header-cell" *ngFor="let stat of occupation.groupQuestions[0]['stats']">{{stat['label']}}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row" *ngFor="let question of occupation.groupQuestions">
        <th class="mat-header-cell">{{question.name}}</th>
        <td class="mat-cell" *ngFor="let stat of question['stats']">
          {{failToSafeHarborQuestion(question) ? null : stat['value']}}
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>

