<div class="dashboard-header">
  <div class="d-flex">
    <div class="flex-grow-1">
      <h1 class="dashboard-header__title">Surveys</h1>
      <p class="dashboard-header__desc">List of surveys</p>
    </div>
    <a mat-fab color="primary" class="" title="Create survey" *ngIf="isEDOwner" [routerLink]="['../start']">
      <mat-icon>add</mat-icon>
    </a>
  </div>
</div>


<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<div class="row mt-4">
  <div class="col-12">
    <mat-card class="material mat-typography">

      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="space-between">

          <div class="results-wrapper">

            <table class="w100" mat-table matSort [dataSource]="dataSource">

              <ng-container matColumnDef="title">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-flat-button
                          (click)="viewSurvey(element)">{{element['title']}}</button>
                </td>
              </ng-container>

              <ng-container matColumnDef="period">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Period</th>
                <td mat-cell *matCellDef="let element"> {{element['start_date'] | formatDate | date}}
                  - {{element['end_date'] | formatDate | date}}
                </td>
              </ng-container>

              <ng-container matColumnDef="users_count">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Survey takers</th>
                <td mat-cell *matCellDef="let element"> {{element['users_count']}}</td>
              </ng-container>


              <ng-container matColumnDef="completion_rate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Completion rate</th>
                <td mat-cell *matCellDef="let element"> 0</td>
              </ng-container>


              <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element"><app-survey-status-pill [survey]="element"></app-survey-status-pill></td>
              </ng-container>

              <ng-container matColumnDef="results">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Results</th>
                <td mat-cell *matCellDef="let element">
                  <button
                    mat-flat-button
                    [routerLink]="[edPath+'/results/'+element.id]"
                  >
                    View results
                  </button>
                </td>
              </ng-container>


              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button aria-label="actions" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">

                    <button mat-menu-item
                      (click)="previewSurvey(element)"
                      *ngIf="isEDSurveyManager">
                      <mat-icon>pageview</mat-icon>
                      <span>View survey</span>
                    </button>

                    <button mat-menu-item
                      (click)="edit(element)"
                      *ngIf="isEDMember"
                      [disabled]="!canEdit(element)">
                      <mat-icon>event</mat-icon>
                      <span>Edit Survey Information</span>
                    </button>

                    <button mat-menu-item
                      (click)="inviteUsers(element)"
                      *ngIf="isEDSurveyManager">
                      <mat-icon>person_add</mat-icon>
                      <span>Invite Users</span>
                    </button>

                    <button mat-menu-item
                      (click)="editDefinition(element)"
                      *ngIf="isEDOwner">
                      <mat-icon>format_shapes</mat-icon>
                      <span>Change Questions & Layout</span>
                    </button>

                    <button mat-menu-item
                      (click)="duplicate(element)"
                      *ngIf="isEDOwner">
                      <mat-icon>filter_none</mat-icon>
                      <span>Duplicate</span>
                    </button>

                    <button mat-menu-item
                      (click)="unpause(element)"
                      *ngIf="element.status == 'paused' && isEDMember">
                      <mat-icon>play_circle_outline</mat-icon>
                      <span>Start</span>
                    </button>

                    <button mat-menu-item
                      (click)="pause(element)"
                      *ngIf="element.status == 'running' && isEDMember">
                      <mat-icon>pause</mat-icon>
                      <span>Pause</span>
                    </button>

                    <button mat-menu-item
                      (click)="cancel(element)"
                      *ngIf="isEDMember"
                      [disabled]="!canEdit(element)">
                      <mat-icon>not_interested</mat-icon>
                      <span>Cancel</span>
                    </button>

                    <button mat-menu-item
                      (click)="delete(element)"
                      *ngIf="isEDMember">
                      <mat-icon>delete_forever</mat-icon>
                      <span>Delete</span>
                    </button>

                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
          </div>

          <mat-paginator [pageSizeOptions]="[10,30,50,100,500]" showFirstLastButtons></mat-paginator>

        </div>

      </mat-card-content>
    </mat-card>
  </div>
</div>


