<app-message
    #message
    [opened]="false"
>
  <button type="button" app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>


<div class="container-fluid cover-page" *ngIf="surveyResults">

  <div class="row text-center">
    <h1>{{survey.title}}</h1>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <app-ed-logo [logoPath]="ED.logo_url"></app-ed-logo>
    </div>
  </div>

  <div class="row mt-5" *ngIf="survey.description">
    <div class="col">
      <div class="container break-line-class" [innerHTML]="survey.description"></div>
    </div>
  </div>

  <div class="row mt-5" *ngIf="survey.cover_page_text">
    <div class="col">
      <div class="container" [innerHTML]="survey.cover_page_text"></div>
    </div>
  </div>

</div>

<div class="container-fluid cover-page" *ngIf="surveyResults && survey.show_businesses_list_in_results">
  <div class="row text-center">
    <h1>Businesses</h1>
  </div>
  <div class="row">
    <div class="col">
      <ul style="columns: 2; column-gap: 2em;">
        <li
          *ngFor="let business of surveyResults.businesses"
        >{{business?.name}}</li>
      </ul>
    </div>
  </div>
</div>

<div class="table-of-contents-label">
  <a href="#table-of-contents" (click)="scrollToElement('table-of-contents', $event)">
    Table of Contents
  </a>
</div>


<div class="container-fluid" *ngIf="surveyResults">
  <div class="row table-of-contents">
    <div class="col">
      <mat-card-title [id]="'table-of-contents'">
        <h1>Table of contents</h1>
      </mat-card-title>
      <ul>
        <ng-container *ngFor="let sectionIndex of showTableOfContents.showSectionIndexes; let i = index">
          <li mat-list-item>
            <a href="#section-{{i+1}}" (click)="scrollToElement('section-'+(i+1), $event)">
              {{i + 1}}. {{surveyResults.sections[sectionIndex]['name']}}
            </a>
            <ul *ngIf="!surveyResults.sections[sectionIndex].is_compensation_survey">
              <li *ngFor="let questionIndex of showTableOfContents.sections[sectionIndex].showQuestionIndexes; let qi = index">
                <a href="#{{surveyResults.sections[sectionIndex].questions[questionIndex]['id']}}"
                  (click)="scrollToElement(surveyResults.sections[sectionIndex].questions[questionIndex]['id'], $event)">
                  {{i + 1}}.{{qi + 1}} {{surveyResults.sections[sectionIndex].questions[questionIndex]['name']}}
                </a>
                <!-- DEFAULT -->
                <!-- <span *ngIf="sID==193 && i == 3 && qi == 1">
                  <img src="assets/DRMA/Image_4.jpg" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
                <span *ngIf="sID==193 && i == 10 && qi == 5">
                  <img src="assets/DRMA/Image_5.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
                <span *ngIf="sID==193 && i == 14 && qi == 0">
                  <img src="assets/DRMA/EMS.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span> -->

                <!-- Category 1 -->
                <!-- <span *ngIf="sID==193 && i == 6 && qi == 1">
                  <img src="assets/DRMA/Image_4.jpg" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
                <span *ngIf="sID==193 && i == 11 && qi == 2">
                  <img src="assets/DRMA/Image_5.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;"><br />
                </span>
                <span *ngIf="sID==193 && i == 16 && qi == 2">
                  <img src="assets/DRMA/EMS.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span> -->

                <!-- Category 2 -->
                <!-- <span *ngIf="sID==193 && i == 3 && qi == 1">
                  <img src="assets/DRMA/Image_4.jpg" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
                <span *ngIf="sID==193 && i == 10 && qi == 5">
                  <img src="assets/DRMA/Image_5.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
                <span *ngIf="sID==193 && i == 14 && qi == 1">
                  <img src="assets/DRMA/EMS.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span> -->


                <span *ngIf="sID==193 && i == 3 && qi == 7">
                  <img src="assets/DRMA/Image_4.jpg" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
                <span *ngIf="sID==193 && i == 10 && qi == 11">
                  <img src="assets/DRMA/Image_5.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
                <span *ngIf="sID==193 && i == 14 && qi == 8">
                  <img src="assets/DRMA/EMS.png" class="ng-star-inserted" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;padding: 25px;">
                </span>
              </li>
            </ul>
            <ul *ngIf="surveyResults.sections[sectionIndex].is_compensation_survey">
              <li *ngFor="let industry of filteredCompensationData.industries; let ii = index">
                <ng-container *ngIf="industry.occupations.length">
                  <a href="#{{sanitizeHref(industry.title)}}" (click)="scrollToElement(sanitizeHref(industry.title), $event)">
                    {{i + 1}}.{{ii + 1}} {{industry.title}}
                  </a>
                    <ul>
                      <ng-container *ngFor="let occupation of industry.occupations; let oi = index">
                        <li *ngIf="occupation['questions']">
                          <a href="#{{sanitizeHref(industry.title + '__' + occupation.title)}}" (click)="scrollToElement(sanitizeHref(industry.title + '__' + occupation.title), $event)">
                            {{i + 1}}.{{ii + 1}}.{{oi + 1}} {{occupation.title}}
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                </ng-container>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
      <img *ngIf="sID==193" style="text-align: center;display: block;margin-left: auto;margin-right: auto;width: 100%;" src="assets/DRMA/Image_6.jpg" >
    </div>
  </div>


  <div class="row survey-section" *ngFor="let sectionIndex of showTableOfContents.showSectionIndexes; let i = index">
      <div class="col" id="section-{{i+1}}">

        <ng-container *ngIf="!surveyResults.sections[sectionIndex]['is_compensation_survey']">
          <app-survey-results-section
            [survey]="survey"
            [sectionResult]="surveyResults.sections[sectionIndex]"
            [sectionNumber]="i"
            [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
          ></app-survey-results-section>
        </ng-container>

        <ng-container *ngIf="surveyResults.sections[sectionIndex]['is_compensation_survey']">
          <app-survey-print-results-compensation
            [survey]="survey"
            [results]="filteredCompensationData"
            [sectionNumber]="i"
            [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
          ></app-survey-print-results-compensation>
        </ng-container>

      </div>
  </div>

</div>
<div id="end_of_page">&nbsp;</div>
