<app-message
    #message
    [opened]="false"
    class="m-2"
></app-message>
<div class="row survey-section" *ngFor="let industry of results.industries; let ii = index">
  <ng-container *ngIf="industry.occupations.length">
    <div class="col">
      <h1 class="ml-3" id="{{sanitizeHref(industry.title)}}">
        {{sectionNumber+1}}.{{ii + 1}} {{industry.title}} ({{industry.occupations.length}} occupations)
      </h1>
      <div *ngIf="!hasIndustryOccupationsWithData(industry)">
        <mat-card class="m-3 p-3 w-100 mat-card">
          <mat-card-header class="mat-card-header">
            <div class="mt-3">
              There are no occupations with data for this industry.
            </div>
          </mat-card-header>
        </mat-card>
      </div>
      <ng-container *ngFor="let occupation of industry.occupations; let oi = index">
        <ng-container *ngIf="occupation['questions']">
          <div *ngIf="oi % 2 == 0 && oi > 0" class="page-break"></div>
          <mat-card class="m-3 p-3 w-100 mat-card">
            <mat-card-header class="mat-card-header">
              <div class="mat-card-header-text">
                <h3 id="{{sanitizeHref(industry.title + '__' + occupation.title)}}">
                  {{sectionNumber+1}}.{{ii + 1}}.{{oi + 1}} {{occupation.title}}
                </h3>
                  <div class="mt-3">
                    <app-survey-results-occupation-result
                      [survey]="survey"
                      [occupation]="occupation"
                      [surveyResultsSection]="results"
                      [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
                    ></app-survey-results-occupation-result>
                  </div>
              </div>
            </mat-card-header>
          </mat-card>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
