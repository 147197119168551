import {AuthApiService} from "../../core/auth-api.service";

export class NavigationModel {
  public model: any[];

  constructor(private auth: AuthApiService) {

    if (!this.auth.user) {
      this.model = [];
      return;
    }

    this.model = [];

    const EDPath = this.auth.getEDPath();

    //add ED related menu entries
    if (this.auth.user.ed) {

      this.model.push(
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'dashboard',
          url: EDPath + '/dashboard'
        },
        {
          id: 'results',
          title: 'Results',
          type: 'item',
          icon: 'pie_chart',
          url: EDPath + '/results'
        },
      );
    }

    if (this.auth.isEDOwner()) {
      this.model.push(
        {
          id: 'surveys',
          title: 'Surveys',
          type: 'collapse',
          icon: 'poll',
          url: EDPath + '/surveys',
          children: [
            {
              id: 'new',
              title: 'Start new survey',
              type: 'item',
              icon: 'play_circle_outline',
              url: EDPath + '/surveys/start'
            },
            {
              id: 'ed',
              title: 'View Surveys',
              type: 'item',
              icon: 'poll',
              url: EDPath + '/surveys/list'
            }
          ]
        }
      );
    } else if (this.auth.isEDSurveyManager()) {
      this.model.push(
        {
          id: 'surveys',
          title: 'Surveys',
          type: 'collapse',
          icon: 'poll',
          url: EDPath + '/surveys',
          children: [
            {
              id: 'ed',
              title: 'View Surveys',
              type: 'item',
              icon: 'poll',
              url: EDPath + '/surveys/list'
            }
          ]
        }
      );
    }

      if (this.auth.isEDMember()) {
        this.model.push(
        // {
        //   id: 'stats',
        //   title: 'Statistics',
        //   type: 'item',
        //   icon: 'pie_chart',
        //   url: EDPath + '/stats'
        // },
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          icon: 'supervisor_account',
          url: EDPath + '/users'
        },
        {
          id: 'settings',
          title: 'Settings',
          type: 'collapse',
          icon: 'settings',
          url: EDPath + '/settings',
          children: [
            // {
            //   id: 'ed',
            //   title: 'Account Profile',
            //   type: 'item',
            //   icon: 'folder_open',
            //   url: EDPath + '/settings/profile'
            // },
            {
              id: 'members',
              title: 'Members',
              type: 'item',
              icon: 'person',
              url: EDPath + '/settings/members'
            },
            {
              id: 'branding',
              title: 'Site & Branding',
              type: 'item',
              icon: 'public',
              url: EDPath + '/settings/site-branding'
            },
          ]
        });
    }

    if (this.auth.isSuperAdmin()) {
      this.model.push(
        {
          id: 'admin',
          title: 'Admin',
          type: 'collapse',
          icon: 'home',
          children: [
            {
              id: 'admin-dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'home',
              url: '/admin'
            },
            {
              id: 'admin-ed',
              title: 'ED Accounts',
              type: 'item',
              icon: 'folder',
              url: '/admin/ed',
            },
            {
              id: 'admin-ed-owners',
              title: 'ED Owners',
              type: 'item',
              icon: 'supervisor_account',
              url: '/admin/ed-owners'
            },
            {
              id: 'admin-users',
              title: 'Users',
              type: 'item',
              icon: 'supervisor_account',
              url: '/admin/users'
            },
            {
              id: 'admin-survey-definitions',
              title: 'Survey templates',
              type: 'item',
              icon: 'layers',
              url: '/admin/survey-definitions'
            },
            // {
            //   id: 'admin-events-log',
            //   title: 'Events log',
            //   type: 'item',
            //   icon: 'event_note',
            //   url: '/admin/events-log'
            // },
            // {
            //   id: 'admin-notifications-log',
            //   title: 'Notifications log',
            //   type: 'item',
            //   icon: 'mail',
            //   url: '/admin/notifications-log'
            // }
          ]
        });
    }

    this.model.push({
      id: 'profile',
      title: 'My Account',
      icon: 'person',
      type: 'item',
      url: '/profile'
    });

  }
}
