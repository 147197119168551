import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-block-matrix-checkboxes-result',
  templateUrl: './block-matrix-checkboxes-result.component.html',
  styleUrls: ['./block-matrix-checkboxes-result.component.scss']
})
export class BlockMatrixCheckboxesResultComponent implements OnInit {

  @Input("question") question: SurveyResultsQuestion;


  constructor() {
  }

  ngOnInit() {
  }

  getPercentage(row, col) {
    if (!this.question.results.answers || !this.question.results.answers[row]) {
      return null;
    }
    console.log(this.question.results.answers[row], "1")
    return this.question.results.answers[row][col] / this.question.results.answered_count;
  }


}
